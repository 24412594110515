import React, { useState } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";

export default function BulkNotifications() {
  const [openSnackbar] = useSnackbar();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const initialValues = {
    title: "",
    subtitle: "",
  };

  const onSubmit = (values, { setFieldValue, resetForm }) => {
    console.log(values);
    setIsBtnDisabled(true);

    ApiService({
      method: "POST",
      route: "promotion/push",
      body: values,
    }).then((response) => {
      console.log("r" + response.data.status_code);
      if (response.data.status_code === 1) {
        setFieldValue("title", "");
        setFieldValue("subtitle", "");
        resetForm(initialValues);
      }
      setIsBtnDisabled(false);
      openSnackbar(response.data.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    subtitle: Yup.string().required("Required"),
  });

  return (
    <div className="mainInnerView">
      <h1>Bulk Notifications</h1>
      <div className="listPaper">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form style={{ width: "500px" }}>
            <FormikControl
              control="input"
              type="text"
              label="Title"
              name="title"
            />
            <FormikControl
              control="input"
              as="textarea"
              type="text"
              label="Subtitle"
              name="subtitle"
              rows="5"
            />
            <GButton
              type="submit"
              disabled={isBtnDisabled}
              variant="condensed"
              children="Send Notification"
              style={{ display: "flex", margin: "15px 15px 20px auto" }}
            />
          </Form>
        </Formik>
      </div>
    </div>
  );
}
