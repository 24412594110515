import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Search } from "@material-ui/icons";
import useDebounce from "../useDebounce";

const SearchDiv = styled.div`
  display: inline-flex;
  border: 1px solid #e2e2e2;
  background: #fff;
  padding: 8px;
  width: 250px;
  min-width: 130px;
  input {
    border: 0px;
    outline: 0;
    box-shadow: none;
    width: 89%;
    background: transparent;
  }
`;

export const GSearch = ({
  value,
  handleChange,
  handleBlur,
  handleFocus,
  placeholder,
  style,
  inputStyle,
  iconStyle,
  isDebounce,
}) => {
  const [searchWord, setSearchWord] = useState(value || "");
  const debouncedSearchTerm = useDebounce(searchWord, 500);
  useEffect(() => {
    handleChange(debouncedSearchTerm);
  }, [debouncedSearchTerm]);
  return (
    <SearchDiv style={style}>
      <input
        value={isDebounce == true ? searchWord : value}
        onChange={(e) => {
          if (isDebounce == true) {
            setSearchWord(e.target.value);
          } else {
            handleChange(e.target.value);
          }
        }}
        placeholder={placeholder}
        style={inputStyle}
      />

      <Search
        fontSize="small"
        style={Object.assign(
          { verticalAlign: "middle", fontSize: "22px", color: "#ccc" },
          iconStyle
        )}
      />
    </SearchDiv>
  );
};
