import React from "react";
import Input from "./input";
import NumberInput from "./numberInput";
import Textarea from "./textarea";
import Selectbox from "./selectbox";
import SelectOption from "./selectOptions";
import SelectGroupBox from "./selectGroupBox";
import RadioButtons from "./radioButton";
import CheckBox from "./checkBox";
import CheckBoxesGroup from "./checkBoxGroup";
import DatePicker from "./datePicker";
import ToggleSWitch from "./switch";

function formikControl(props) {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "numberInput":
      return <NumberInput {...rest} />;
    case "textarea":
      return <Textarea {...rest} />;
    case "select":
      return <Selectbox {...rest} />;
    case "selectOption":
      return <SelectOption {...rest} />;
    case "selectGroup":
      return <SelectGroupBox {...rest} />;
    case "radio":
      return <RadioButtons {...rest} />;
    case "singleCheckbox":
      return <CheckBox {...rest} />;
    case "checkbox":
      return <CheckBoxesGroup {...rest} />;
    case "date":
      return <DatePicker {...rest} />;
    case "toggle":
      return <ToggleSWitch {...rest} />;
    default:
      return null;
  }
}

export default formikControl;
