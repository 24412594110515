import React, { useState, useEffect } from "react";
import { TableContainer } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { ApiService, ImgUrl, addDefaultSrc } from "../services";
import { Add, Edit, Delete } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import DocumentFormPopup from "../forms/documentForm";
import GLightBox from "../gComponents/gLightBox";
import useDataFctory from "../useDataFactory";
import Modal from "react-modal";

const columns = [
  { id: "title", label: "Title", minWidth: 120 },
  { id: "photo", label: "Photo", minWidth: 50 },
  { id: "created_at", label: "Created", minWidth: 60, align: "center" },
  { id: "actions", label: "Actions", minWidth: 60, align: "center" },
];

export default function Documents(props) {
  const [initialLoading, setInitialLoading] = useState(true);
  const [openSnackbar] = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [linkedId, setLinkedId] = useState("");
  const [selectedDocument, setSelectedDocument] = useState({});
  const [selectedDocumentId, setSelectedDocumentId] = useState("");
  const [isEmpty, setIsEmpty] = useState(true);
  const [docvisible, setDocvisible] = useState(false);
  const [selectedImgPreview, setSelectedImgPreview] = useState(false);
  const [isImgPreview, setImgPreview] = useState(false);
  let user_type =
    props.type === "customer" ? "1" : props.type === "host" ? "2" : "";

  const {
    loading,
    data: documentData,
    refreshData,
    Placeholder,
    TableShimmer,
  } = useDataFctory("documents", false, {
    user_type: user_type,
    user_id: props.value,
  });

  useEffect(() => {
    console.log(props);
    if (props.type) {
      if (props.onChange) {
        props.onChange("documents");
      }
    }
    setLinkedId(props.value);

    if (initialLoading == false && props.value) {
      refreshData({
        user_type: user_type,
        user_id: props.value,
      });
    }
    setInitialLoading(false);
  }, [props.value]);

  const deleteDocument = () => {
    ApiService({
      method: "DELETE",
      route: "documents/" + selectedDocumentId,
    })
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.data.status_code === 1) {
          let selectedIndex = documentData.findIndex(
            (d) => d.id == selectedDocumentId
          );
          if (selectedIndex > -1) {
            documentData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={props.type ? "" : "mainInnerView"}>
      {props.type ? "" : <h1>Documents</h1>}
      {props.value && (
        <GButton
          variant="linkable"
          onClick={() => {
            setSelectedDocument({});
            setIsEmpty(true);
            setDocvisible(true);
          }}
          style={{
            margin: "-40px 0px 10px",
            float: "right",
          }}
        >
          <Add fontSize="large" />
        </GButton>
      )}
      <div className="listPaper">
        {loading === true ? (
          <TableShimmer></TableShimmer>
        ) : (
          <TableContainer>
            {documentData.length === 0 ? (
              <Placeholder />
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documentData.map((c) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData>{c.document_type_title}</TableRowData>
                        <TableRowData>
                          <img
                            alt=""
                            src={ImgUrl("document") + "/" + c.thumb_photo}
                            onError={addDefaultSrc}
                            className="thumbnail"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSelectedImgPreview(c.photo);
                              setImgPreview(true);
                            }}
                          />
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {c.created}
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setSelectedDocument(c);
                              setIsEmpty(false);
                              setDocvisible(true);
                            }}
                          >
                            <Edit fontSize="small" />
                          </GButton>

                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setSelectedDocumentId(c.id);
                              setDialogOpen(true);
                            }}
                          >
                            <Delete fontSize="small" />
                          </GButton>
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Document"
          text="Are you sure you want to delete this document?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteDocument();
          }}
        ></GConfirmDialog>
      )}

      {isImgPreview === true && (
        <GLightBox
          images={selectedImgPreview}
          isImgPreview={isImgPreview}
          setImgPreview={setImgPreview}
          imgType="document"
        ></GLightBox>
      )}

      <Modal
        isOpen={docvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setDocvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Document Form Modal"
      >
        <DocumentFormPopup
          docvisible={docvisible}
          setDocvisible={setDocvisible}
          type={props.type}
          data={selectedDocument}
          isEmpty={isEmpty}
          linkedId={linkedId}
          userType={
            props.type === "customer" ? "1" : props.type === "host" ? "2" : ""
          }
          onSubmit={(data) => {
            let selectedIndex = documentData.findIndex((p) => p.id == data.id);
            if (selectedIndex > -1) {
              documentData[selectedIndex] = { ...data };
            } else {
              documentData.push(data);
            }
          }}
        />
      </Modal>
    </div>
  );
}
