import React from "react";
import { Field, ErrorMessage } from "formik";
import TextErrors from "./formikTextErrors";

const radioLabelStyle = {
  display: "block",
  padding: "0 15px 5px 5px",
  margin: "0",
};
function checkBoxes(props) {
  const { label, value, name, onChange,checked, ...rest } = props;
  return (
    <div className="form-control">
            <div style={{ display: "flex" }}>
 
              <label style={radioLabelStyle} htmlFor={name}>
              <input
      type="checkbox"
      checked={checked} // use checked to have a controlled component 
      name={name}
          onChange={onChange}
      id={name}
    />

                {label}
              </label>
            </div>
      <ErrorMessage name={name} component={TextErrors} />
    </div>
  );
}

export default checkBoxes;
