import React, { useState } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import GButton from "../gComponents/gButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GCitiesTagsInput from "../gComponents/gCitiesInput";

export default function StaffForm(props) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();

  const initialValues = {
    first_name: !props.isEmpty ? props.data.first_name : "",
    last_name: !props.isEmpty ? props.data.last_name : "",
    email: !props.isEmpty ? props.data.email : "",
    password: "",
    cities: !props.isEmpty ? props.data.cities || [] : [],
  };

  const onSubmit = (values) => {
    setIsDisabled(true);
    if (!props.isEmpty) {
      if (!values.password) {
        delete values.password;
      }
    }
    console.log(values);

    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "staff/" + props.data.id : "staff";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log("r" + response.data.status_code);
        if (response.data.status_code === 1) {
          if (props.onSubmit) {
            props.onSubmit(response.data.data);
          }
          props.setSFormVisible(false);
        }
        setIsDisabled(false);
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .required("Required")
      .min(2, "First name should be atleast 2 characters"),
    email: Yup.string().email("Invalid Email Address").required("Required"),
    password: !props.isEmpty
      ? ""
      : Yup.string()
          .required("Required")
          .min(6, "Password is too short - should be 6 chars minimum"),
    cities: Yup.string().required("Required"),
  });

  if (props.sFormVisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? "Edit" : "Create"} Staff
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ values, isValid, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="First Name*"
                name="first_name"
              />
              <FormikControl
                control="input"
                type="text"
                label="Last Name"
                name="last_name"
              />
              <FormikControl
                control="input"
                type="email"
                label="Email ID*"
                name="email"
              />

              <FormikControl
                control="input"
                type="text"
                label={!props.isEmpty ? "Password" : "Password*"}
                name="password"
              />
              <div style={{ margin: "15px" }}>
                <label>Cities</label>
                <GCitiesTagsInput
                  data={values.cities}
                  onSubmit={(tags) => {
                    console.log(tags);
                    values.cities = tags;
                    setFieldValue(tags);
                  }}
                  showButton={false}
                />
              </div>
              <FormikControl
                control="input"
                type="text"
                name="cities"
                style={{ display: "none" }}
              />
              <br />
              <GButton
                variant="condensed"
                disabled={isDisabled}
                style={{
                  margin: "0 15px",
                  width: "95%",
                  color: "#fff",
                }}
                children={!props.isEmpty ? "Update" : "Create"}
                type="submit"
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
