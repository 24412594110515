import React, { useEffect, useState } from "react";
import { ApiService } from "../services";
import { Edit, Delete } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import { Formik, Form, FieldArray } from "formik";
import FormikControl from "../formikComponents/formikControl";
import * as Yup from "yup";
import { useSnackbar } from "react-simple-snackbar";
import GConfirmDialog from "../gComponents/gConfirmDialog";

function TaxForm(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [selectedTax, setSelectedTax] = useState();
  const [taxData, setTaxData] = useState([]);
  const initialValues = {
    options: props.data,
  };

  const validationSchema = Yup.object({});

  useEffect(() => {
    document.getElementById("resetButton").click();
    setTaxData(props.data);
    initialValues.options = props.data;
  }, [props]);

  const handleUpdateVariant = (values, ind) => {
    console.log(ind + values.options[ind]);
    ApiService({
      method: "PUT",
      route: "taxes/" + values.options[ind].id,
      body: values.options[ind],
    }).then((response) => {
      console.log("r" + response.data.status_code);
      if (response.data.status_code === 1) {
        taxData[ind] = { ...response.data.data, isDisabled: true };
        setTaxData(taxData);
      }
      openSnackbar(response.data.message);
      return;
    });
  };

  const handleDeleteTax = () => {
    ApiService({
      method: "DELETE",
      route: "taxes/" + selectedTax,
    }).then((response) => {
      console.log(response.data.status_code);
      if (response.data.status_code === 1) {
        props.onTaxRemove(selectedTax);
      }
      openSnackbar(response.data.message);
      return;
    });
  };

  return (
    <div style={props.style}>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, resetForm }) => (
          <Form>
            <FieldArray name="options">
              {(fieldArrayProps) => {
                const { form } = fieldArrayProps;
                const { values } = form;
                const { options } = values;

                return (
                  <>
                    {options.map((o, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "inline-flex",
                            width: "100%",
                            padding: "0px 0 0 15px",
                            boxSizing: "border-box",
                            borderBottom: "1px solid #e2e2e2",
                          }}
                          className="arrayFields"
                        >
                          <FormikControl
                            control="input"
                            type="text"
                            label="Title"
                            name={`options[${index}].title`}
                            divstyle={{
                              padding: "10px 5px",
                              maxWidth: "250px",
                              minWidth: "250px",
                            }}
                            style={{ width: "92%", margin: "5px 0" }}
                            disabled={
                              options[index].isDisabled === false ? false : true
                            }
                          />
                          <FormikControl
                            control="input"
                            type="text"
                            label="Percentage"
                            name={`options[${index}].percentage`}
                            divstyle={{
                              padding: "10px 5px",
                              maxWidth: "200px",
                            }}
                            style={{ width: "92%", margin: "5px 0" }}
                            disabled={
                              options[index].isDisabled === false ? false : true
                            }
                            maxLength={3}
                            onChange={(event) => {
                              const re = /^[0-9.\b]+$/;
                              if (
                                event.target.value &&
                                !re.test(event.target.value)
                              ) {
                                return;
                              } else {
                                setFieldValue(
                                  `options[${index}].percentage`,
                                  event.target.value
                                );
                              }
                            }}
                          />
                          {options[index].isDisabled === false ? (
                            <span
                              style={{
                                textAlign: "right",
                                width: "calc(100% - 420px)",
                                padding: "13px",
                                marginTop: "25px",
                              }}
                            >
                              <GButton
                                type="button"
                                children="Update"
                                variant="condensed"
                                onClick={(e) => {
                                  handleUpdateVariant(values, index);
                                }}
                              />
                              <GButton
                                type="button"
                                children="Cancel"
                                variant="linkable"
                                onClick={(e) => {
                                  setFieldValue(
                                    `options[${index}].isDisabled`,
                                    true
                                  );
                                  setFieldValue(
                                    `options[${index}].title`,
                                    props.data[index].title
                                  );
                                  setFieldValue(
                                    `options[${index}].percentage`,
                                    props.data[index].percentage
                                  );
                                }}
                              />
                            </span>
                          ) : (
                            <span
                              style={{
                                textAlign: "right",
                                width: "calc(100% - 420px)",
                                padding: "13px",
                                marginTop: "25px",
                              }}
                            >
                              <GButton
                                type="button"
                                variant="linkable"
                                onClick={(e) => {
                                  setFieldValue(
                                    `options[${index}].isDisabled`,
                                    false
                                  );
                                }}
                              >
                                <Edit />
                              </GButton>
                              <GButton
                                type="button"
                                variant="linkable"
                                onClick={(e) => {
                                  setSelectedTax(options[index].id);
                                  setDialogOpen(true);
                                }}
                              >
                                <Delete />
                              </GButton>
                            </span>
                          )}
                          <button
                            id="resetButton"
                            style={{ display: "none" }}
                            onClick={() => resetForm()}
                          ></button>
                        </div>
                      );
                    })}
                  </>
                );
              }}
            </FieldArray>
          </Form>
        )}
      </Formik>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Tax"
          text="Are you sure you want to delete this tax?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            handleDeleteTax();
          }}
        ></GConfirmDialog>
      )}
    </div>
  );
}
export default TaxForm;
