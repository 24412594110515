import axios from "axios";
import placeholder from "./assets/images/placeholders/list-placeholder.png";
import itemPlaceholder from "./assets/images/placeholders/p-item.png";
import userPlaceholder from "./assets/images/placeholders/f-placeholder.png";
import AppLogo from "./assets/images/logo.png";
import moment from "moment-timezone";
import Axios from "./Axios";

export function addDefaultSrc(e, type) {
  // e.target.src = placeholder;

  e.target.src =
    type && type === "item"
      ? itemPlaceholder
      : type === "user" || type === "host" || type === "admin"
      ? userPlaceholder
      : placeholder;
  // return e.target.src;
}
export function addDefaultLogo(e) {
  e.target.src = AppLogo;
  // return e.target.src;
}
export function ImgUrl(imgType) {
  if (imgType) {
    var ImageUrl = process.env.REACT_APP_IMAGE_URL +
    "/" +
    localStorage.getItem("teamId") + "/" + imgType;

    return ImageUrl;
  }
}

export function isPermissionExist(per) {
  let s = localStorage.getItem("settings")
    ? JSON.parse(localStorage.getItem("settings"))
    : localStorage.getItem("settings");
  if (s) {
    let exist = s?.permissions?.includes(per);

    return exist;
  }
}

export function getProductTitle() {
  let s = localStorage.getItem("settings")
  ? JSON.parse(localStorage.getItem("settings"))
  : localStorage.getItem("settings");
if (s?.currency_symbol) {
  return s.product_display || "Products"
}
else{
  return "Products"
}
}
export function getProductTitleIdent() {
  let s = localStorage.getItem("settings")
  ? JSON.parse(localStorage.getItem("settings"))
  : localStorage.getItem("settings");
if (s?.currency_symbol) {
  return s.product || "product"
}
else{
  return "product"
}
}

export function getUserType() {
  let s = localStorage.getItem("settings")
    ? JSON.parse(localStorage.getItem("settings"))
    : localStorage.getItem("settings");
  if (s) {
    let uType =
      s?.user?.user_type == "sa"
        ? true
        : s?.user?.user_type == "admin"
        ? true
        : false;
    // console.log(uType);
    return uType;
  }
}

export async function ApiService(service) {
  const getParams = () => {
    console.log(service.body);
    var formBody = [];
    for (var property in service.body) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(service.body[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    console.log(formBody);
    formBody = formBody.join("&");
    return "?" + formBody;
  };
  // console.log(Object.keys(service.body).length)
  let urlParam =
    service.method === "GET"
      ? service.body && Object.keys(service.body).length > 0
        ? getParams()
        : ""
      : "";
  let postData = service.method === "GET" ? "" : service.body;

  try {
    let response1 = await axios({
      method: service.method,
      url:
        process.env.REACT_APP_API_URL +  "/" +
        localStorage.getItem("teamId") +"/a/" + service.route + "" + urlParam,
      data: postData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        timezone: moment.tz.guess(),
      },
    });
    console.log(response1);
    // if (response1.data.status_code === 417) {
    //   localStorage.clear();
    // }
    return response1;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

export async function ApiDownloadService(method, hUrl, param) {
  // var formBody = [];
  // console.log(param + "--" + method);

  // console.log(moment.tz.guess());
  let urlParam = method === "GET" ? (param ? param : "") : "";
  let postData = method === "GET" ? "" : param;
  // console.log(process.env.REACT_APP_API_URL);

  try {
    let response1 = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL + "/" +
      localStorage.getItem("teamId") + "/a/" + hUrl + "" + urlParam,
      data: postData,
      responseType: "arraybuffer",
      headers: {
        "Content-Type": undefined,
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        timezone: moment.tz.guess(),
      },
    });
    console.log(response1);
    return response1.data;
  } catch (error) {
    console.log(error.response);
    return error;
  }
}

export async function FileApiService(method, hUrl, param) {
  try {
    var fd = new FormData();
    fd.append("image", param);

    let response1 = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL +  "/" +
      localStorage.getItem("teamId") +"/a/upload?type=" + hUrl,
      data: fd,
      headers: {
        ContentType: undefined,
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    });
    return response1.data;
  } catch (error) {
    return error;
  }
}

export async function ApiLoginService(method, hUrl, param) {
  // var formBody = [];
  // for (var property in param) {
  //   var encodedKey = encodeURIComponent(property);
  //   var encodedValue = encodeURIComponent(param[property]);
  //   formBody.push(encodedKey + "=" + encodedValue);
  // }
  try {
    let response1 = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL + "/" + hUrl,
      data: param,
      headers: {
        Accept: "application/json",
        Authorization: "",
      },
    });
    console.log(response1);
    return response1.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function LogoutService(hUrl) {
  try {
    let response1 = await axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL + "/" +
      localStorage.getItem("teamId") + "/" + hUrl,
      data: "",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    });
    return response1.data;
  } catch (error) {
    return error;
  }
}
