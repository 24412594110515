import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import GEditable from "../gComponents/gEditable";
import GImageEditor from "../gComponents/gImageEditor";
import { ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { GImagePicker } from "../gComponents/gImagePicker";
import GText from "../gComponents/gText";

export default function BsicSettings(props) {
  const [openSnackbar] = useSnackbar();
  const [linksData, setLinksData] = useState({
    currency_symbol: {},
    admin_phone: {},
    admin_email: {},
    admin_logo: {},
    email_logo: {},
    project_name: {},
    country_code: {}, 
    admin_whatsapp: {},
    distance_unit: {},
  }); 

  useEffect(() => {
    getBasicSettings(); 
  }, []);

  const getBasicSettings = () => {
    ApiService({ method: "GET", route: "setting/basic" })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          setLinksData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
 

  function updateField(newValue, selectedFieldId, selectedKey) {
    console.log(newValue + selectedFieldId);
    // return;
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        value: newValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          linksData[selectedKey] = response.data.data;
          setLinksData({ ...linksData }); 
        }  
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText g2 bold text={"Basic Settings"} style={{ marginBottom: "15px" }} />

      <div className="listPaper">
        <div
          style={{
            display: "inline-block",
            width: "225px",
            verticalAlign: "top",
            boxSizing: "border-box",
            padding: "20px",
          }}
        >
          <GText text={"Project Logo"} style={{ marginBottom: "10px" }} />
          <div
            style={{
              background: "#f4f5f9",
              padding: "10px",
              display: "inline-block",
            }}
          >
            <GImageEditor
              image={ImgUrl("logo") + "/" + linksData.admin_logo?.value}
              onSubmit={(newVal, fieldId) => {
                updateField(newVal, fieldId, "admin_logo");
              }}
              id={linksData.admin_logo?.id}
              type="logo"
              isPreviewImg={true}
            />
          </div>

          <hr style={{ margin: "20px 0" }} />

          <GText text={"Email Logo"} style={{ marginBottom: "10px" }} />
          <div
            style={{
              background: "#f4f5f9",
              padding: "10px",
              display: "inline-block",
            }}
          >
            {/* <GImageEditor
                image={ImgUrl("logo") + "/" + linksData.email_logo.value}
                onSubmit={(newVal, fieldId) => {
                  updateField(newVal, fieldId, "email_logo");
                }}
                id={linksData.email_logo.id}
                type="logo"
                isPreviewImg={true}
              /> */}

            {linksData.email_logo?.id ? (
              <GImagePicker
                type="logo"
                alt={linksData.project_name?.value}
                height={"auto"}
                width={100}
                radius={1}
                //editor
                editorProps={{
                  height: 200,
                  width: 200,
                  radius: 100,
                }}
                serverProps={{
                  size: 200,
                }}
                onSuccess={(v) => {
                  console.log(v);
                  updateField(v.image, linksData.email_logo?.id, "email_logo");
                }}
                isPreviewImg={true}
                default={ImgUrl("logo") + "/" + linksData.email_logo?.value}
                style={{
                  minHeight: linksData.email_logo?.value ? "50px" : "100px",
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          style={{
            display: "inline-block",
            width: "calc(100% - 500px)",
            minWidth: "350px",
            maxWidth: "600px",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <GEditable
            key={linksData.currency_symbol?.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={linksData.currency_symbol?.value}
            className={props.display}
            placeholder={linksData.currency_symbol?.title}
            id={linksData.currency_symbol?.id}
            onSubmit={(newVal, fieldId) => {
              updateField(newVal, fieldId, "currency_symbol");
            }}
            style={{ margin: "10px 0 20px" }}
          />
          <GEditable
            key={linksData.admin_phone?.id}
            type="number"
            maxLength={15}
            title={true}
            editable={false}
            defaultValue={linksData.admin_phone?.value}
            className={props.display}
            placeholder={linksData.admin_phone?.title}
            id={linksData.admin_phone?.id}
            onSubmit={(newVal, fieldId) => {
              updateField(newVal, fieldId, "admin_phone");
            }}
            style={{ margin: "10px 0 20px" }}
          />
          <GEditable
            key={linksData.admin_email?.id}
            type="email"
            title={true}
            editable={false}
            required={true}
            defaultValue={linksData.admin_email?.value}
            className={props.display}
            placeholder={linksData.admin_email?.title}
            id={linksData.admin_email?.id}
            onSubmit={(newVal, fieldId) => {
              updateField(newVal, fieldId, "admin_email");
            }}
            style={{ margin: "10px 0 20px" }}
          />
          <GEditable
            key={linksData.project_name?.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={linksData.project_name?.value}
            className={props.display}
            placeholder={linksData.project_name?.title}
            id={linksData.project_name?.id}
            onSubmit={(newVal, fieldId) => {
              linksData.project_name.value = newVal;
              setLinksData(linksData);
              updateField(newVal, fieldId, "project_name");
            }}
            style={{ margin: "10px 0 20px" }}
          />
          {linksData.country_code?.id && (
            <GEditable
              key={linksData.country_code?.id}
              type="number"
              title={true}
              editable={false}
              defaultValue={linksData.country_code?.value}
              className={props.display}
              placeholder={linksData.country_code?.title}
              id={linksData.country_code?.id}
              onSubmit={(newVal, fieldId) => {
                updateField(newVal, fieldId, "country_code");
              }}
              style={{ margin: "10px 0 20px" }}
            />
          )}
          
          {linksData.admin_whatsapp?.id && (
            <GEditable
              key={linksData.admin_whatsapp?.id}
              type="number"
              maxLength={15}
              title={true}
              editable={false}
              defaultValue={linksData.admin_whatsapp?.value}
              className={props.display}
              placeholder={linksData.admin_whatsapp?.title}
              id={linksData.admin_whatsapp?.id}
              onSubmit={(newVal, fieldId) => {
                updateField(newVal, fieldId, "admin_whatsapp");
              }}
              style={{ margin: "10px 0 20px" }}
            />
          )}
          {linksData.distance_unit?.id && (
            <GEditable
              key={linksData.distance_unit?.id}
              type="alpha"
              title={true}
              editable={false}
              defaultValue={linksData.distance_unit?.value}
              className={props.display}
              placeholder={linksData.distance_unit?.title}
              id={linksData.distance_unit?.id}
              onSubmit={(newVal, fieldId) => {
                updateField(newVal, fieldId, "distance_unit");
              }}
              style={{ margin: "10px 0 20px" }}
            />
          )}
        </div>
      
      </div>
    </div>
  );
}
