import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import GEditable from "../gComponents/gEditable";
import { useSnackbar } from "react-simple-snackbar";
import GSwitch from "../gComponents/gSwitch";
import styled from "styled-components";
import { Info } from "@material-ui/icons";
import TooltipComponent from "../gComponents/gTooltip";
import useSettings from "../useSettings";
import GText from "../gComponents/gText";

export const DIV = styled.div`
  display: inline-flex;
  width: 85%;
  padding: 5px;
  align-items: center;

  & .inputStyle {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    span {
      text-align: center;
      background: #f2f2f2;
      border: 1px solid #f2f2f2;
      color: #555;
      padding: 0.5rem 2px;
      font-size: 14px;
      width: 60px;
    }
  }
`;

export default function WalletSettings(props) {
  const settings = useSettings();
  const [openSnackbar] = useSnackbar();
  const [walletData, setWalletsData] = useState({
    earn_order_max_coin_limits: {},
    invite_text: {},
    order_cashback: {},
    order_complete_points_percentage: {},
    point_value: {},
    referral_rule: {},
    sender_referral_points: {},
    spend_order_points_amount_percentage: {},
    user_referral_points: {},
    wallet_host: {},
    wallet_refund: {},
    withdraw_host: {},
  });

  useEffect(() => {
    getWalletSettings();
  }, []);

  const getWalletSettings = () => {
    ApiService({ method: "GET", route: "setting/wallet" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setWalletsData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function updateField(newValue, selectedFieldId, keyTitle) {
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        value: newValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          if (keyTitle) {
            walletData[keyTitle].value = newValue;
            setWalletsData(walletData);
          }
        } else {
          getWalletSettings();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={"Wallet Settings"}
        style={{ marginBottom: "15px" }}
      />

      <div className="listPaper">
        <GText
          semi
          g4
          text={"Referral Points"}
          style={{ marginBottom: "15px" }}
        />
        <DIV>
          <GText text={"Friend Referral"} />
          <div className="inputStyle">
            <GEditable
              type="number"
              editable={false}
              id={walletData.sender_referral_points.id}
              defaultValue={walletData.sender_referral_points.value}
              onSubmit={(newVal, id) =>
                updateField(newVal, id, "sender_referral_points")
              }
              style={{ margin: "0px", width: "calc(100% - 60px)" }}
              inputStyle={{
                minWidth: "70px",
                maxWidth: "80px",
                width: "45%",
                float: "right",
              }}
            />
            <span>Points</span>
          </div>
        </DIV>
        <DIV>
          <GText text={"New User"} />
          <div className="inputStyle">
            <GEditable
              type="number"
              editable={false}
              id={walletData.user_referral_points.id}
              defaultValue={walletData.user_referral_points.value}
              onSubmit={(newVal, id) =>
                updateField(newVal, id, "user_referral_points")
              }
              style={{ margin: "0px", width: "calc(100% - 60px)" }}
              inputStyle={{
                minWidth: "70px",
                maxWidth: "80px",
                width: "45%",
                float: "right",
              }}
            />
            <span>Points</span>
          </div>
        </DIV>
        <DIV>
          <GText
            text={"Restrict on Booking Completion"}
            style={{ display: "inline-block", width: "auto" }}
          />
          <TooltipComponent
            title="If turned on, then both the users (Friend Referral & New User) will get points only after the New User will complete its 1st booking successfully."
            position="bottom"
            id={walletData.referral_rule.id}
          >
            <Info fontSize="small" />
          </TooltipComponent>
          <GSwitch
            id={walletData.referral_rule.id}
            uId={walletData.referral_rule.id}
            currentValue={walletData.referral_rule.value}
            style={{ marginLeft: "auto" }}
            onSwitchChange={(newVal, id) =>
              updateField(newVal, id, "referral_rule")
            }
          />
        </DIV>

        <hr />

        <GText
          semi
          g4
          text={"Cashback Points"}
          style={{ margin: "15px 0", display: "inline-block", width: "auto" }}
        />
        <GSwitch
          id={walletData.order_cashback.id}
          uId={walletData.order_cashback.id}
          currentValue={walletData.order_cashback.value}
          style={{
            display: "inline-block",
            paddingLeft: "20px",
            verticalAlign: "middle",
          }}
          onSwitchChange={(newVal, id) =>
            updateField(newVal, id, "order_cashback")
          }
        />
        <DIV>
          <GText text={"Cashback per Booking"} />
          <div className="inputStyle">
            <GEditable
              type="number"
              editable={false}
              id={walletData.order_complete_points_percentage.id}
              defaultValue={walletData.order_complete_points_percentage.value}
              onSubmit={(newVal, id) =>
                updateField(newVal, id, "order_complete_points_percentage")
              }
              style={{ margin: "0px", width: "calc(100% - 100px)" }}
              inputStyle={{
                minWidth: "70px",
                maxWidth: "80px",
                width: "45%",
                float: "right",
              }}
            />
            <span style={{ width: "100px" }}>% of subtotal</span>
          </div>
        </DIV>
        <DIV>
          <GText text={"Maximum Points earned per Booking"} />
          <div className="inputStyle">
            <GEditable
              type="number"
              editable={false}
              id={walletData.earn_order_max_coin_limits.id}
              defaultValue={walletData.earn_order_max_coin_limits.value}
              onSubmit={(newVal, id) =>
                updateField(newVal, id, "earn_order_max_coin_limits")
              }
              style={{ margin: "0px", width: "calc(100% - 60px)" }}
              inputStyle={{
                minWidth: "70px",
                maxWidth: "80px",
                width: "45%",
                float: "right",
              }}
            />
            <span>Points</span>
          </div>
        </DIV>

        <hr />
        <GText semi g4 text={"Discount"} style={{ marginBottom: "15px" }} />
        <DIV>
          <GText text={"Maximum discount"} />
          <div className="inputStyle">
            <GEditable
              type="number"
              maxLength={3}
              editable={false}
              maxVal={100}
              title={"Maximum discount"}
              id={walletData.spend_order_points_amount_percentage.id}
              defaultValue={
                walletData.spend_order_points_amount_percentage.value
              }
              onSubmit={(newVal, id) => {
                updateField(newVal, id, "spend_order_points_amount_percentage");
              }}
              style={{ margin: "0px", width: "calc(100% - 100px)" }}
              inputStyle={{
                minWidth: "75px",
                maxWidth: "100px",
                width: "45%",
                float: "right",
              }}
            />
            <span style={{ width: "100px" }}>% of subtotal</span>
          </div>
        </DIV>
        <DIV>
          <GText text={"Points Conversion Rate"} />
          <div className="inputStyle">
            <GEditable
              type="number"
              editable={false}
              id={walletData.point_value.id}
              defaultValue={walletData.point_value.value}
              onSubmit={(newVal, id) => updateField(newVal, id, "point_value")}
              style={{ margin: "0px", width: "calc(100% - 75px)" }}
              inputStyle={{
                minWidth: "75px",
                maxWidth: "100px",
                width: "45%",
                float: "right",
              }}
            />
            <span style={{ width: "75px" }}>
              Points / {settings?.currency_symbol}
            </span>
          </div>
        </DIV>

        <hr />

        <GText
          semi
          g4
          text={"Host Wallet"}
          style={{ margin: "15px 0", display: "inline-block", width: "auto" }}
        />
        <GSwitch
          id={walletData.wallet_host.id}
          uId={walletData.wallet_host.id}
          currentValue={walletData.wallet_host.value}
          style={{
            display: "inline-block",
            paddingLeft: "20px",
            verticalAlign: "middle",
          }}
          onSwitchChange={(newVal, id) =>
            updateField(newVal, id, "wallet_host")
          }
        />

        {walletData.wallet_host.value === "1" ? (
          <DIV style={{ display: "flex" }}>
            <GText text={"Allow Withdraw"} />
            <GSwitch
              id={walletData.withdraw_host.id}
              uId={walletData.withdraw_host.id}
              currentValue={walletData.withdraw_host.value}
              style={{ marginLeft: "auto" }}
              onSwitchChange={(newVal, id) =>
                updateField(newVal, id, "withdraw_host")
              }
            />
          </DIV>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
