import React, { useState, useEffect, useRef } from "react";
import { ApiService } from "../services";
import "../css/tags.css";
import { Close } from "@material-ui/icons";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import GButton from "./gButton";

function GCitiesInput(props) {
  const [cityTags, setCityTags] = useState([]);
  const cityTagsRef = useRef(null);
  const [showGeoSuggestions, setShowGeoSuggestions] = useDetectOutsideClick(
    cityTagsRef,
    false
  );
  const [suggestions, setSuggestions] = useState([]);

  console.log(props.data);
  const getGeoTags = () => {
    ApiService({ method: "GET", route: "cities" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          response.data.data.map((s) => {
            s.selected = false;
            if (props.data.includes(JSON.stringify(s.id))) {
              s.selected = true;
            }
          });

          setSuggestions([...response.data.data]);
          console.log(suggestions);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getGeoTags();
  }, []);

  const handleAddition = (event) => {
    if (event.key === "Enter") {
      setCityTags([...cityTags, { title: event.target.value }]);
      event.target.value = "";
    }
  };

  const handleValueChange = (t, id) => {
    let checkedArray = [];
    let currentChecked = [...suggestions];
    console.log(currentChecked);
    currentChecked.map((c, index) => {
      if (c.id == t.id) {
        c.selected = !c.selected;
      }
      return c;
    });
    setSuggestions(currentChecked);

    const checkedItems = suggestions
      .filter((sug) => sug.selected === true)
      .map((s) => {
        return s;
      });

    console.log(checkedItems);
    checkedItems.map((c) => {
      checkedArray.push(c.id);
    });
    console.log(checkedArray);
    setCityTags(checkedArray);
    if (props.showButton === false) {
      props.onSubmit(checkedArray);
    }
  };

  return (
    <div ref={cityTagsRef}>
      {props.showButton !== false ? (
        <GButton
          variant="linkable"
          style={{
            marginTop: "-35px",
            float: "right",
            textDecoration: "underline",
          }}
          children="Update"
          onClick={() => props.onSubmit(cityTags)}
        />
      ) : (
        ""
      )}
      <div className="react-tags">
        <ul className="react-tags-list">
          {suggestions
            .filter((sug) => sug.selected === true)
            .map((t, i) => {
              return (
                <li className="react-tags__selected-tag" key={i}>
                  {t.title}
                  <Close onClick={() => handleValueChange(t, i)} />
                </li>
              );
            })}
        </ul>
        <input
          type="text"
          className="react-tags-input"
          placeholder="Add Cities.."
          readOnly={true}
          onFocus={() => {
            setShowGeoSuggestions(true);
          }}
          onKeyUp={() => handleAddition}
        />
      </div>
      <div className="react-tags-suggestions">
        {showGeoSuggestions === true ? (
          <div className="react-tags__suggestions">
            <ul>
              {suggestions.filter((sug) => sug.selected === false).length >
              0 ? (
                suggestions
                  .filter((sug) => sug.selected === false)
                  .map((s, i) => {
                    return (
                      <li
                        key={i}
                        className="react-tags__selected-tag"
                        onClick={() => {
                          setShowGeoSuggestions(false);
                          handleValueChange(s, i);
                        }}
                      >
                        {s.title}
                      </li>
                    );
                  })
              ) : (
                <p style={{ color: "#777" }}> &nbsp;&nbsp;No data found</p>
              )}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default GCitiesInput;
