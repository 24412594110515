import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
} from "../styledComponents/tableStyle";
import { Info } from "@material-ui/icons";
import GEditable from "../gComponents/gEditable";
import GSwitch from "../gComponents/gSwitch";
import TooltipComponent from "../gComponents/gTooltip";
import GText from "../gComponents/gText";
import GSelectbox from "../gComponents/gSelectbox";

const billingTypes = [
  {
    label: "Hourly",
    value: "hourly",
  },
  {
    label: "Daily",
    value: "daily",
  },
];

export default function OrderSettings() {
  const [orderSettingsData, setOrderSettingsData] = useState({
    min_order: {},
    free_delivery: {},
    delivery_otp: {},
    max_cash_order: {},
  });
  const [businessSettingsData, setBusinessSettingsData] = useState({
    min_billing: {},
    billng_type: {},
    max_list_distance: {},
  });
  const [openSnackbar] = useSnackbar();
  const [minOrderSwitch, setMinOrderSwitchVal] = useState();
  const [maxOrderSwitch, setMaxOrderSwitchVal] = useState();
  const [freeDeliverySwitch, setFreeDeliverySwitchVal] = useState();
  const [otpDeliverySwitch, setOtpDeliverySwitchVal] = useState();

  const getOrderSettings = () => {
    ApiService({ method: "GET", route: "setting/order" })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          setOrderSettingsData(response.data.data);
          setMinOrderSwitchVal(response.data.data.min_order?.value);
          setMaxOrderSwitchVal(response.data.data.max_cash_order?.value);
          setFreeDeliverySwitchVal(response.data.data.free_delivery?.value);
          setOtpDeliverySwitchVal(response.data.data.delivery_otp?.value);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBusinessSettings = () => {
    ApiService({ method: "GET", route: "setting/business" })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          setBusinessSettingsData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOrderSettings();
    getBusinessSettings();
  }, []);

  const switchActionValue = (newValue, sId, keyTitle) => {
    if (!(newValue === "1" || newValue === 1)) {
      //setMinOrderVal(newValue);
      updateSetting(newValue, sId, keyTitle);
    }
  };

  function updateField(newValue, selectedFieldId, keyTitle) {
    console.log(newValue);
    console.log(selectedFieldId);
    updateSetting(newValue, selectedFieldId, keyTitle);
  }
  function updateSetting(newValue, selectedFieldId, keyTitle) {
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        value: newValue,
      },
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          if (keyTitle) {
            orderSettingsData[keyTitle] = response.data.data;
            setOrderSettingsData({ ...orderSettingsData });
            businessSettingsData[keyTitle] = response.data.data;
            setBusinessSettingsData({ ...businessSettingsData });
          }
        } else {
          getOrderSettings();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText g2 bold text={"Other Settings"} style={{ marginBottom: "20px" }} />
      <div className="listPaper">
        <Table>
          <TableBody>
            {orderSettingsData.min_order?.id && (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>
                  {orderSettingsData.min_order.title}
                  <TooltipComponent
                    title="You can turn it on if you do not want to recieve bookings below a certain amount."
                    position="bottom"
                    id="min_order"
                  >
                    <Info fontSize="small" />
                  </TooltipComponent>
                </TableRowData>
                <TableRowData>
                  <GSwitch
                    id={orderSettingsData.min_order.id}
                    uId={orderSettingsData.min_order.id}
                    currentValue={minOrderSwitch != "0" ? "1" : "0"}
                    onSwitchChange={(newVal, id) => {
                      setMinOrderSwitchVal(newVal);
                      switchActionValue(newVal, id, "min_order");
                    }}
                  />
                </TableRowData>
                <TableRowData style={{ width: "200px", padding: "0" }}>
                  {minOrderSwitch != "0" ? (
                    <GEditable
                      type="text"
                      title={false}
                      editable={false}
                      defaultValue={orderSettingsData.min_order.value}
                      id={orderSettingsData.min_order.id}
                      onSubmit={(newVal, id) => {
                        orderSettingsData.min_order.value = newVal;
                        setOrderSettingsData(orderSettingsData);
                        updateField(newVal, id, "min_order");
                      }}
                      style={{ margin: "0px 0 10px", width: "80%" }}
                      inputStyle={{ minWidth: "80px", width: "60%" }}
                    />
                  ) : (
                    ""
                  )}
                </TableRowData>
              </TableRow>
            )}

            {orderSettingsData.free_delivery?.id && (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                style={{ background: "#fff" }}
              >
                <TableRowData>
                  {orderSettingsData.free_delivery.title}
                  <TooltipComponent
                    title="If turned on, then it offers 0 delivery charges when the order amount is more than the amount set."
                    position="bottom"
                    id={orderSettingsData.free_delivery.id}
                  >
                    <Info fontSize="small" />
                  </TooltipComponent>
                </TableRowData>
                <TableRowData>
                  <GSwitch
                    id={orderSettingsData.free_delivery.id}
                    uId={orderSettingsData.free_delivery.id}
                    currentValue={freeDeliverySwitch !== "0" ? "1" : "0"}
                    onSwitchChange={(newVal, id) => {
                      setFreeDeliverySwitchVal(newVal);
                      switchActionValue(newVal, id, "free_delivery");
                    }}
                  />
                </TableRowData>
                <TableRowData style={{ width: "200px", padding: "0" }}>
                  {freeDeliverySwitch !== "0" ? (
                    <GEditable
                      type="text"
                      title={false}
                      editable={false}
                      defaultValue={orderSettingsData.free_delivery.value}
                      id={orderSettingsData.free_delivery.id}
                      onSubmit={(newVal, id) => {
                        orderSettingsData.free_delivery.value = newVal;
                        setOrderSettingsData(orderSettingsData);
                        updateField(newVal, id, "free_delivery");
                      }}
                      style={{ margin: "0px 0 10px", width: "80%" }}
                      inputStyle={{ minWidth: "80px", width: "60%" }}
                    />
                  ) : (
                    ""
                  )}
                </TableRowData>
              </TableRow>
            )}

            {orderSettingsData.max_cash_order?.id && (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                style={{ background: "#fff" }}
              >
                <TableRowData>
                  {orderSettingsData.max_cash_order.title}
                </TableRowData>
                <TableRowData>
                  <GSwitch
                    id={orderSettingsData.max_cash_order.id}
                    uId={orderSettingsData.max_cash_order.id}
                    currentValue={maxOrderSwitch != "0" ? "1" : "0"}
                    onSwitchChange={(newVal, id) => {
                      setMaxOrderSwitchVal(newVal);
                      switchActionValue(newVal, id, "max_cash_order");
                    }}
                  />
                </TableRowData>
                <TableRowData style={{ width: "200px", padding: "0" }}>
                  {maxOrderSwitch != "0" ? (
                    <GEditable
                      type="text"
                      title={false}
                      editable={false}
                      defaultValue={orderSettingsData.max_cash_order.value}
                      id={orderSettingsData.max_cash_order.id}
                      onSubmit={(newVal, id) => {
                        orderSettingsData.max_cash_order.value = newVal;
                        setOrderSettingsData(orderSettingsData);
                        updateField(newVal, id, "max_cash_order");
                      }}
                      style={{ margin: "0px 0 10px", width: "80%" }}
                      inputStyle={{ minWidth: "80px", width: "60%" }}
                    />
                  ) : (
                    ""
                  )}
                </TableRowData>
              </TableRow>
            )}

            {orderSettingsData.delivery_otp?.id && (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                style={{ background: "#fff" }}
              >
                <TableRowData>
                  {orderSettingsData.delivery_otp.title}
                </TableRowData>
                <TableRowData>
                  <GSwitch
                    id={orderSettingsData.delivery_otp.id}
                    uId={orderSettingsData.delivery_otp.id}
                    currentValue={otpDeliverySwitch != "0" ? "1" : "0"}
                    onSwitchChange={(newVal, id) => {
                      setOtpDeliverySwitchVal(newVal);
                      updateField(newVal, id, "delivery_otp");
                    }}
                  />
                </TableRowData>
                <TableRowData
                  style={{ width: "200px", padding: "0" }}
                ></TableRowData>
              </TableRow>
            )}

            {businessSettingsData.billing_type?.id && billingTypes && (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                style={{ background: "#fff" }}
              >
                <TableRowData>
                  {businessSettingsData.billing_type.title || "Billing Type"}
                </TableRowData>
                <TableRowData></TableRowData>
                <TableRowData style={{ width: "200px", padding: "0" }}>
                  <GSelectbox
                    placeholder="Select value"
                    defaultvalue={businessSettingsData.billing_type.value}
                    display="inline-flex"
                    data={billingTypes}
                    id={businessSettingsData.billing_type.id}
                    onChange={(newval) =>
                      updateField(
                        newval,
                        businessSettingsData.billing_type.id,
                        "billing_type"
                      )
                    }
                    option_key="label"
                    option_value="value"
                    style={{ border: "0", borderBottom: "1px solid #e2e2e2" }}
                  />
                </TableRowData>
              </TableRow>
            )}

            {businessSettingsData.min_billing?.id && (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>
                  {businessSettingsData.min_billing.title ||
                    "Minimum number of hours/days"}
                  &nbsp;({businessSettingsData.billing_type?.value})
                </TableRowData>
                <TableRowData></TableRowData>
                <TableRowData style={{ width: "200px", padding: "0" }}>
                  <GEditable
                    type="text"
                    title={false}
                    editable={false}
                    defaultValue={businessSettingsData.min_billing.value}
                    id={businessSettingsData.min_billing.id}
                    onSubmit={(newVal, id) => {
                      businessSettingsData.min_billing.value = newVal;
                      setOrderSettingsData(businessSettingsData);
                      updateField(newVal, id, "min_billing");
                    }}
                    style={{ margin: "0px 0 10px", width: "80%" }}
                    inputStyle={{ minWidth: "80px", width: "60%" }}
                  />
                </TableRowData>
              </TableRow>
            )}

            {businessSettingsData.max_list_distance?.id && (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                style={{ background: "#fff" }}
              >
                <TableRowData>
                  {businessSettingsData.max_list_distance?.title ||
                    "Max List Limit"}{" "}
                  (In kms)
                </TableRowData>
                <TableRowData></TableRowData>
                <TableRowData style={{ width: "200px", padding: "0" }}>
                  <GEditable
                    type="number"
                    title={false}
                    editable={false}
                    defaultValue={businessSettingsData.max_list_distance.value}
                    id={businessSettingsData.max_list_distance.id}
                    onSubmit={(newVal, id) => {
                      businessSettingsData.max_list_distance.value = newVal;
                      setOrderSettingsData(businessSettingsData);
                      updateField(newVal, id, "max_list_distance");
                    }}
                    style={{ margin: "0px 0 10px", width: "80%" }}
                    inputStyle={{ minWidth: "80px", width: "60%" }}
                  />
                </TableRowData>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
