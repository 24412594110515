import React from "react";
import { Field, ErrorMessage } from "formik";
import TextErrors from "./formikTextErrors";
import {
  HomeOutlined,
  BusinessCenterOutlined,
  PinDropOutlined,
  Info,
} from "@material-ui/icons";
import TooltipComponent from "../gComponents/gTooltip";

const radioLabelStyle = {
  display: "inline-block",
  padding: "0 8px 0 8px",
};
function radioButton(props) {
  const {
    label,
    name,
    options,
    selected,
    key_title,
    style,
    icons,
    key_value,
    ...rest
  } = props;
  return (
    <div className="form-control">
      {label ? (
        <label
          style={{
            fontSize: "0.98rem",
            color: "#333",
            marginBottom: "5px",
          }}
        >
          {label}
        </label>
      ) : (
        ""
      )}
      <Field name={name} {...rest}>
        {({ field }) => {
          return options.map((option, index) => {
            return (
              <div
                key={index}
                style={Object.assign(
                  {},
                  {
                    display: "inline-block",
                    background:
                      selected === option[key_value]
                        ? "#EBAE25"
                        : "transparent",
                    color: selected === option[key_value] ? "#fff" : "#000",
                  },
                  props.divstyle
                )}
              >
                <input
                  type="radio"
                  id={name + "_option_" + option[key_value]}
                  {...field}
                  value={option[key_value]}
                  checked={field.value == option[key_value]}
                  style={style}
                />
                <label
                  style={Object.assign({}, radioLabelStyle, props.labelStyle, {
                    color: selected == option[key_value] ? "#fff" : "#000",
                  })}
                  htmlFor={name + "_option_" + option[key_value]}
                >
                  {icons}
                  {icons === true ? (
                    <React.Fragment>
                      {option[key_title] === "HOME" ? (
                        <HomeOutlined
                          style={{
                            verticalAlign: "sub",
                            color:
                              selected === option[key_value] ? "#fff" : "#000",
                          }}
                        />
                      ) : option[key_title] === "WORK" ? (
                        <BusinessCenterOutlined
                          style={{
                            verticalAlign: "sub",
                            color:
                              selected === option[key_value] ? "#fff" : "#000",
                          }}
                        />
                      ) : (
                        <PinDropOutlined
                          style={{
                            verticalAlign: "sub",
                            color:
                              selected === option[key_value] ? "#fff" : "#000",
                          }}
                        />
                      )}{" "}
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  {option[key_title]}

                  {option.tooltipValue ? (
                    <TooltipComponent
                      title={option.tooltipValue}
                      position="bottom"
                      id={option[key_title]}
                    >
                      <Info fontSize="small" />
                    </TooltipComponent>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            );
          });
        }}
      </Field>
      <ErrorMessage name={name} component={TextErrors} />
    </div>
  );
}

export default radioButton;
