import React, { useState, useEffect } from "react";
import "../css/switch.css";

function GSwitch(props) {
  //const [value, setValue] = React.useState(props.defaultvalue ? '1' : '0');
  // const [value, setValue] = useState(props.defaultvalue === 1 || props.defaultvalue === '1' ? '1' : '0');
  const [checkedValue, setCheckedValue] = useState(
    props.currentValue === 1 || props.currentValue === "1" ? true : false
  );

  useEffect(() => {
    //  props.onChange(value)
    // action on update of movies
    setCheckedValue(
      props.currentValue === 1 || props.currentValue === "1" ? true : false
    );
  }, [props]);

  const handleSwitchToggle = (e) => {
    //setValue(e.target.value);
    console.log(checkedValue);
    if (props.onSwitchChange) {
      props.onSwitchChange(
        checkedValue === false ? "0" : "1",
        props.uId,
        props.keyType ? props.keyType : ""
      );
    }
  };
  //   console.log(props);

  return (
    <div style={props.style}>
      <input
        className="react-switch-checkbox"
        id={props.id}
        defaultValue={checkedValue}
        type="checkbox"
        checked={checkedValue}
        disabled={props.disabled}
        onChange={(e) => {
          console.log(e);
          handleSwitchToggle();
        }}
        //onChange={(e) => { setValue(checkedValue === true ? '1' : '0');}}
      />
      <label
        style={{ float: props.float }}
        className="react-switch-label"
        htmlFor={props.id}
        onClick={(e) => {
          console.log(props.currentValue);
          setCheckedValue(
            props.currentValue === 1 || props.currentValue === "1"
              ? false
              : true
          );
        }}
        //onClick={handleToggle}
      >
        <span className={`react-switch-button`} />
      </label>
    </div>
  );
}

export default GSwitch;
