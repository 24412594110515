import React, { useState } from "react";
import { ApiService, ImgUrl, addDefaultSrc } from "../services";
import GImageEditor from "../gComponents/gImageEditor";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GButton from "../gComponents/gButton";
import styled from "styled-components";
import { useSnackbar } from "react-simple-snackbar";
import useDataFctory from "../useDataFactory";

const BannerDiv = styled.div`
  width: 33%;
  box-sizing: border-box;
  display: inline-block;
  padding: 10px 15px;
  position: relative;
  &:hover .hoverImg {
    display: block;
  }
  & img {
    width: 100%;
    object-fit: cover;
    height: 180px;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #e2e2e2;
  }
  & .hoverImg {
    display: none;
    height: 170px;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    top: 0px;
    left: 15px;
    right: 15px;
    padding: 5px;
    margin: 15px 5px 0;
    position: absolute;
    box-sizing: border-box;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    width: 50%;
  }
  @media (max-width: 959px) {
    width: 100%;
  }
`;

export default function Banners(props) {
  const [openSnackbar] = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedBannerId, setSelectedBannerId] = useState("");
  const { data: bannerData } = useDataFctory("banners", false, {
    type: "mobile",
  });

  const deleteBanner = () => {
    ApiService({
      method: "DELETE",
      route: "offer-banners/" + selectedBannerId,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = bannerData.findIndex(
            (p) => p.id == selectedBannerId
          );
          if (selectedIndex > -1) {
            bannerData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateField = (newValue) => {
    var send_data = {
      type: "mobile",
      photo: newValue,
    };
    ApiService({ method: "POST", route: "offer-banners", body: send_data })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          bannerData.push(response.data.data);
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="rightInnerView">
      <h2>Banners</h2>
      <p style={{ fontSize: "15px" }}>Use images with size 400(h) x 700(w).</p>
      <br />
      <div className="listPaper">
        {bannerData.map((b) => {
          return (
            <BannerDiv key={b.id}>
              <img
                alt=""
                src={ImgUrl("banners") + "/" + b.photo}
                onError={addDefaultSrc}
              />
              <div className="hoverImg">
                <GButton
                  variant="linkable"
                  children="delete"
                  style={{
                    float: "right",
                    color: "#fff",
                    textDecoration: "underline",
                    paddingTop: "135px",
                  }}
                  onClick={(e) => {
                    setSelectedBannerId(b.id);
                    setDialogOpen(true);
                  }}
                >
                  Delete
                </GButton>
              </div>
            </BannerDiv>
          );
        })}

        <GImageEditor
          onSubmit={updateField}
          id="photo"
          type="banners"
          divstyle={{
            width:
              window.innerWidth <= 960
                ? "100%"
                : window.innerWidth <= 1279 && window.innerWidth > 960
                ? "50%"
                : "33%",
          }}
          style={{ height: "180px", paddingTop: "80px", margin: "0" }}
        />
      </div>
      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Banner"
          text="Are you sure you want to delete this banner?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteBanner();
          }}
        ></GConfirmDialog>
      )}
    </div>
  );
}
