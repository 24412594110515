import React from "react";
import styled from "styled-components";
import GText from "./gText";
import placeholder from "../assets/images/placeholders/empty-placeholder.svg";

const PlaceHolder = styled.div`
  padding: 40px 0;
`;

export default function GEmpty(props) {
  return (
    <PlaceHolder>
      <div id="noRecords" style={{ textAlign: "center" }}>
        <img
          src={placeholder}
          alt=""
          style={{ width: "100px", marginBottom: "10px" }}
        />
        <GText g3 semi text={props.title || "No Records"} />
        <GText text={props.subtitle || "No data found for this search"} />
      </div>
    </PlaceHolder>
  );
}
