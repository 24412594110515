import React, { useState } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useLocation } from "react-router-dom";
import useDataFctory from "../useDataFactory";
import GButton from "../gComponents/gButton";

export default function HostForm(props) {
  const [openSnackbar] = useSnackbar();
  const location = useLocation();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const { data: citiesData } = useDataFctory("cities", false);
  console.log(props.data);
  const initialValues = {
    first_name: props.data ? props.data.first_name : "",
    last_name: props.data ? props.data.last_name : "",
    email: props.data ? props.data.email : "",
    phone: props.data ? props.data.phone : "",
    password: "",
    commission: props.data ? props.data.commission : 0,
    city_id: props.data ? props.data?.city_id || "" : "",
    description: props.data ? props.data.description : "",
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    if (props.data) {
      if (!values.password) {
        delete values.password;
      }
    }
    console.log(values);

    var hitMethod = props.data ? "PUT" : "POST";
    var hitUrl = props.data ? "hosts/" + props.data.id : "hosts";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log("r" + response.data.status_code);
        if (response.data.status_code === 1) {
          if (props.onSubmit) {
            props.onSubmit(response.data.data);
            openSnackbar(response.data.message);
          }

          if (!props.data) {
            openSnackbar(response.data.message);
            if (location.pathname === "/hosts") {
              window.location.reload(false);
            } else {
              window.location = "/hosts";
            }
          }
          props.setChvisible(false);
          setIsBtnDisabled(false);
        } else {
          openSnackbar(response.data.message);
          setIsBtnDisabled(false);
          return;
        }
      }
    );
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid Email Address").required("Required"),
    phone: Yup.string().required("Required"),
    password: !props.data ? Yup.string().required("Required") : "",
  });

  if (props.chvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? "Edit" : "Create"} Host
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="First Name"
                name="first_name"
                onChange={(event) => {
                  const re = /^[A-Za-z \b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("first_name", event.target.value);
                  }
                }}
                divstyle={{
                  width: "50%",
                  display:'inline-block',
                  boxSizing: "border-box",
                  verticleAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label="Last Name"
                name="last_name"
                onChange={(event) => {
                  const re = /^[A-Za-z \b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("last_name", event.target.value);
                  }
                }}
               divstyle={{
                  width: "50%",
                  display:'inline-block',
                  boxSizing: "border-box",
                  verticleAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="email"
                label="Email ID"
                name="email"
              />
              <FormikControl
                control="input"
                type="password"
                label="Password"
                name="password"
              />
              <FormikControl
                control="input"
                type="text"
                label="Phone"
                name="phone"
                onChange={(event) => {
                  const re = /^[0-9-+\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("phone", event.target.value);
                  }
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label="Service Fee (in %)"
                name="commission"
                divstyle={{
                  width: "50%",
                  display:'inline-block',
                  boxSizing: "border-box",
                  verticleAlign: "top",
                }}
              />

              {citiesData && (
                <FormikControl
                  control="select"
                  as="select"
                  label="City"
                  name="city_id"
                  options={citiesData}
                  key_title="title"
                  key_value="id"
                  divstyle={{
                    width: "50%",
                    display:'inline-block',
                    boxSizing: "border-box",
                    verticleAlign: "top",
                  }}
                />
              )}

<FormikControl
              control="input"
              as="textarea"
              label="Description"
              name="description"
              rows="4"
            />

              <br />
              <GButton
                type="submit"
                disabled={isBtnDisabled}
                variant="condensed"
                children={props.data ? "Update" : "Create"}
                style={{ width: "calc(100% - 30px", margin: "0 15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
