import React from "react";
// import styled from "styled-components";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { getProductTitleIdent } from "../services";

const useStyles = makeStyles((theme) => ({
  SettingRoute: {
    color: "#000",

    "& a": {
      color: "#000",
      cursor: "pointer",
      display: "block",
      padding: "6px 20px",
      fontSize: "13.5px",
      textDecoration: "none",
      fontWeight: "450",
      letterSpacing: "-0.2px",

      "&:hover": {
        color: "#EBAE25",
      },
    },
    "& a.active": {
      color: "#EBAE25",
    },
  },
}));
var headerStyle = {
  fontSize: "15px",
  margin: "0px",
  fontWeight: "550",
  padding: "0 20px",
  marginBottom: "10px",
};

var lineStyle = {
  width: "90%",
  marginTop: "10px",
  marginBottom: "15px",
  border: "0px",
  borderTop: "1px solid #eee",
};

export default function SettingRoutes(props) {
  const classes = useStyles();

  console.log(JSON.stringify(props));
  // export default function SettingView = ({ match }) => {
  return (
    <div className={classes.SettingRoute}>
      <br />
      <h3 style={headerStyle}>General Settings</h3>
      <Link
        to={{ pathname: "/settings/basic", state: "setting" }}
        className={props.url === "/settings/basic" ? "active" : ""}
      >
        Basic Settings
      </Link>
      <Link
        to={{
          pathname: "/settings/social",
          state: "setting",
        }}
        className={props.url === "/settings/social" ? "active" : ""}
      >
        Social Links
      </Link>
      <Link
        to={{
          pathname: "/settings/invoice",
          state: "setting",
        }}
        className={props.url === "/settings/invoice" ? "active" : ""}
      >
        Invoice Settings
      </Link>
      <Link
        to={{
          pathname: "/settings/app",
          state: "setting",
        }}
        className={props.url === "/settings/app" ? "active" : ""}
      >
        App Settings
      </Link>
      <Link
        to={{
          pathname: "/settings/document-types/customer",
          state: "setting",
        }}
        className={props.url === "/settings/document-types" ? "active" : ""}
      >
        Document Types
      </Link>
      <hr style={lineStyle} />

      <h3 style={headerStyle}>Booking Settings</h3>
      <Link
        to={{
          pathname: "/settings/order-notifications/customer",
          state: "setting",
        }}
        className={
          props.url === "/settings/order-notifications" ? "active" : ""
        }
      >
        Notifications{" "}
      </Link>
      <Link
        to={{
          pathname: "/settings/order-cancel-reasons/customer",
          state: "setting",
        }}
        className={
          props.url === "/settings/order-cancel-reasons" ? "active" : ""
        }
      >
        Cancel Reasons{" "}
      </Link>
      <Link
        to={{
          pathname: "/settings/other-settings",
          state: "setting",
        }}
        className={props.url === "/settings/other-settings" ? "active" : ""}
      >
        Other Settings
      </Link>

      <hr style={lineStyle} />

      <h3 style={headerStyle}>Payment Settings</h3>
      <Link
        to={{
          pathname: "/settings/tax",
          state: "setting",
        }}
        className={props.url === "/settings/tax" ? "active" : ""}
      >
        Tax{" "}
      </Link>
      <Link
        to={{
          pathname: "/settings/wallet",
          state: "setting",
        }}
        className={props.url === "/settings/wallet" ? "active" : ""}
      >
        Wallet{" "}
      </Link>
      <hr style={lineStyle} />

      <h3 style={headerStyle}>Content Settings</h3>
      <Link
        to={{
          pathname: "/settings/banners",
          state: "setting",
        }}
        className={props.url === "/settings/banners" ? "active" : ""}
      >
        Banners{" "}
      </Link>

      <Link
        to={{
          pathname: "/settings/faqs/customer",
          state: "setting",
        }}
        className={props.url === "/settings/faqs" ? "active" : ""}
      >
        FAQs{" "}
      </Link>
      <Link
        to={{
          pathname: "/settings/privacy-policy",
          state: "setting",
        }}
        className={props.url === "/settings/privacy-policy" ? "active" : ""}
      >
        Privacy Policy
      </Link>
      <Link
        to={{
          pathname: "/settings/terms-conditions",
          state: "setting",
        }}
        className={props.url === "/settings/terms-conditions" ? "active" : ""}
      >
        Terms & Conditions
      </Link>
      {getProductTitleIdent() == "vehicle" && (
        <Link
          to={{
            pathname: "/settings/disclaimer",
            state: "setting",
          }}
          className={props.url === "/settings/disclaimer" ? "active" : ""}
        >
          Disclaimer
        </Link>
      )}
      {getProductTitleIdent() == "vehicle" && (
        <Link
          to={{
            pathname: "/settings/trip-checklist",
            state: "setting",
          }}
          className={props.url === "/settings/trip-checklist" ? "active" : ""}
        >
          Start Trip Checklist
        </Link>
      )}
      <hr style={lineStyle} />

      <h3 style={headerStyle}>Website Settings</h3>
      <Link
        to={{ pathname: "/settings/website-settings", state: "setting" }}
        className={props.url === "/settings/website-settings" ? "active" : ""}
      >
        Basic Settings
      </Link>
      {/*<Link
        to={{
          pathname: "/settings/web-banners",
          state: "setting",
        }}
        className={props.url === "/settings/web-banners" ? "active" : ""}
      >
        Web Banners{" "}
      </Link>
      */}
      <Link
        to={{ pathname: "/settings/web-plugin", state: "setting" }}
        className={props.url === "/settings/web-plugin" ? "active" : ""}
      >
        Homepage Plugins
      </Link>

      {/*} <Link
        to={{ pathname: "/settings/web-domain", state: "setting" }}
        className={props.url === "/settings/web-domain" ? "active" : ""}
      >
        Link Custom Domain
      </Link> */}
      <hr style={lineStyle} />

      <h3 style={headerStyle}>Geo-location Settings</h3>

      <Link
        to={{
          pathname: "/settings/cities",
          state: "setting",
        }}
        className={props.url === "/settings/cities" ? "active" : ""}
      >
        Cities
      </Link>
      <hr style={lineStyle} />

      <h3 style={headerStyle}>Notification Settings</h3>
      <Link
        to={{
          pathname: "/settings/email-notifications",
          state: "setting",
        }}
        className={
          props.url === "/settings/email-notifications" ? "active" : ""
        }
      >
        Email
      </Link>
      <Link
        to={{
          pathname: "/settings/sms-notifications",
          state: "setting",
        }}
        className={props.url === "/settings/sms-notifications" ? "active" : ""}
      >
        SMS{" "}
      </Link>
      <Link
        to={{
          pathname: "/settings/sms-otp-notifications",
          state: "setting",
        }}
        className={
          props.url === "/settings/sms-otp-notifications" ? "active" : ""
        }
      >
        SMS OTP
      </Link>

      <hr style={lineStyle} />

      <h3 style={headerStyle}>Staff Settings</h3>
      <Link
        to={{
          pathname: "/settings/roles",
          state: "setting",
        }}
        className={props.url === "/settings/roles" ? "active" : ""}
      >
        Roles
      </Link>
      <Link
        to={{
          pathname: "/settings/staff-members",
          state: "setting",
        }}
        className={props.url === "/settings/staff-members" ? "active" : ""}
      >
        Staff Members
      </Link>
      <br />
    </div>
  );
}
