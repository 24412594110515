import React from 'react'; 

const GNoFound = () => {
    return (
        <div>
            <h1 style={{fontSize:'40px',color:'#a2a2a2',    display: 'flex',
    height: 'calc(100vh - 250px)',
    justifyContent: 'center',
    alignItems: 'center' }}>Not found</h1>
        </div>
    );
}

export default GNoFound;
