import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ApiService } from "../services";
import UnitForm from "../forms/productUnitsForm";

const Tab = styled.a`
  font-size: 15px;
  padding: 10px 20px;
  cursor: pointer;
  opacity: 0.6;
  border: 0;
  display: block;
  border-radius: 0px;
  border-left: 2px solid transparent;
  outline: 0;
  ${({ active }) =>
    active &&
    `
    border-left: 2px solid #AA1F20;
    opacity: 1;
  `}
`;
const ButtonGroup = styled.div`
  display: block;
  /* border-bottom: 1px solid #e2e2e2; */
  margin-bottom: 15px;
  width: 130px;
  max-height: 200px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #98989c;
    background: #aa1f20;
    border-radius: 8px;
    display: block;
  }
  &::-webkit-scrollbar-track {
    background: #d9e5f4;
  }
`;

export default function ProductUnitsView(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [productUnits, setProductUnits] = useState();
  const [formVisisble, setFormVisible] = useState(false);

  function getUnits(){
    ApiService({ method: "GET", route: "product/units", body: {product_id:props.productId} })
    .then((response) => {
      console.log(response);
      if (response.data.status_code === 1) {
          setProductUnits(response.data.data);
          setFormVisible(true)
      }
      setIsLoading(false);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
  getUnits()
  }, []);

  return (
    <div style={{ display: "flex" }}>
      {isLoading == true ? (
        ""
      ) : (
        <>
            {formVisisble === true ? (
              <UnitForm
                data={productUnits}
                productId={props.productId}
                onVariantRemove={(ind) =>
                    getUnits()
                }
                onVariantAdd={(data) => {
                    getUnits()
                }}
                isVarStockShow={props.isVarStockShow}
              />
            ) : (
              ""
            )}
        </>
      )}
    </div>
  );
}
