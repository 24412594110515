import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ApiService } from "../services";
import { TableContainer } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { Edit } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GSwitch from "../gComponents/gSwitch";
import GButton from "../gComponents/gButton";
import useDataFctory from "../useDataFactory";
import NotificationFormPopup from "../forms/notificationsForm";
import Modal from "react-modal";

const columns = [
  { id: "event", label: "Event", minWidth: 150 },
  { id: "sms", label: "SMS", minWidth: 100 },
  { id: "email", label: "EMAIL", minWidth: 100 },
  { id: "push", label: "PUSH", minWidth: 100 },
  { id: "actions", label: "Actions", minWidth: 60, align: "center" },
];

const notifyTypesValues = [
  {
    label: "Pickup",
    value: "pickup",
  },
  {
    label: "Delivery",
    value: "delivery",
  },
];

export default function NotificationsSetting(props) {
  let history = useHistory();
  const [openSnackbar] = useSnackbar();
  const [selectedNotification, setSelectedNotification] = useState([]);
  const [nfvisible, setNfvisible] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);

  const {
    loading,
    data: notificationsData,
    Placeholder,
    TableShimmer,
    refreshData,
  } = useDataFctory("notification-settings", false, {
    event_type: props.type,
    user_type: props.userType,
  });

  const [filters, setFilters] = useState({
    event_type: props.type || "",
    user_type: props.userType || "customer",
  });

  useEffect(() => {
    if (initialLoading == false) {
      let data = {
        event_type: props.type,
        user_type: props.userType || "customer",
      };
      setFilters(data);
      refreshData(data);
    }

    setInitialLoading(false);
  }, [props]);

  const handleSwitchValue = (newValue, selectedId, medium, ind) => {
    ApiService({
      method: "PUT",
      route: "notification-settings",
      body: {
        medium: medium,
        id: selectedId,
        status: newValue,
      },
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          notificationsData[ind][medium].status = newValue;
        } else {
          refreshData({
            event_type: props.type,
            user_type: props.userType || "customer",
          });
        }
        console.log(notificationsData);
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {loading === true ? (
        <TableShimmer />
      ) : (
        <>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns
                    .filter((c) => {
                      if (filters.user_type === "admin") {
                        return c.id !== "push" && c.id !== "sms";
                      } else {
                        return c;
                      }
                    })
                    .map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {notificationsData.length == 0 ? (
                  <Placeholder />
                ) : (
                  notificationsData.map((c, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={c.identifier}
                      >
                        <TableRowData
                          style={{
                            BorderBottom: "1px solid #f4f5f9",
                          }}
                        >
                          {c.title}
                        </TableRowData>
                        {filters.user_type !== "admin" && (
                          <TableRowData>
                            {!c.sms || c.sms.status === 2 ? (
                              "-"
                            ) : (
                              <GSwitch
                                id={"sms" + c.identifier}
                                uId={c.sms.id}
                                currentValue={c.sms.status}
                                onSwitchChange={(newvalue, id) =>
                                  handleSwitchValue(newvalue, id, "sms", index)
                                }
                              />
                            )}
                          </TableRowData>
                        )}
                        <TableRowData>
                          {!c.email || c.email.status === 2 ? (
                            "-"
                          ) : (
                            <GSwitch
                              id={"email" + c.identifier}
                              uId={c.email.id}
                              currentValue={c.email.status}
                              onSwitchChange={(newvalue, id) =>
                                handleSwitchValue(newvalue, id, "email", index)
                              }
                            />
                          )}
                        </TableRowData>
                        {filters.user_type !== "admin" && (
                          <TableRowData>
                            {!c.push || c.push.status === 2 ? (
                              "-"
                            ) : (
                              <GSwitch
                                id={"push" + c.identifier}
                                uId={c.push.id}
                                currentValue={c.push.status}
                                onSwitchChange={(newvalue, id) =>
                                  handleSwitchValue(newvalue, id, "push", index)
                                }
                              />
                            )}
                          </TableRowData>
                        )}
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setSelectedNotification(c);
                              setNfvisible(true);
                            }}
                          >
                            <Edit fontSize="small" />
                          </GButton>
                        </TableRowData>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}


      <Modal
        isOpen={nfvisible}
        className="modal modalCentered"
        overlayClassName="modal-overlay"
        onRequestClose={() => setNfvisible(false)}
        style={{
          content: {
            width: "550px",
          },
        }}
        contentLabel="Notification Form Modal"
      >
        <NotificationFormPopup
          nfvisible={nfvisible}
          setNfvisible={setNfvisible}
          data={selectedNotification}
          userType={props.userType}
          onSubmit={() => {
            refreshData({
              event_type: props.type,
              user_type: props.userType || "customer",
            });
          }}
        />
      </Modal>
    </div>
  );
}
