import React, { useState, useEffect } from "react";
import "../css/invoice.css";
import { ApiService, ImgUrl, addDefaultLogo,getProductTitle } from "../services";
import useSettings from "../useSettings";
import { useParams } from "react-router-dom";

export default function OrderThermalInvoice() {
  const setting = useSettings();
  let { bookingId } = useParams();
  const [invoiceData, setInvoiceData] = useState({});
  const [linksData, setLinksData] = useState({
    invoice_logo: {},
    invoice_header_text: {},
    invoice_footer_text: {},
  });

  const getInvoiceSettings = () => {
    ApiService({ method: "GET", route: "setting/invoice" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setLinksData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const getOrderDetail = () => {
      ApiService({ method: "GET", route: "orders/" + bookingId })
        .then((response) => {
          console.log(response.data);
          if (response.data.status_code === 1) {
            setInvoiceData(response.data.data);
            getInvoiceSettings();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    console.log(localStorage.getItem("teamId"));
    console.log(localStorage.getItem("access_token"));
    console.log(setting);
    if (
      localStorage.getItem("teamId") && localStorage.getItem("access_token")) {
      getOrderDetail();
    } else {
      window.location = "/";
    }
  }, []);

  return (
    <div className="thermalInvoiceDiv">
      <img
        src={ImgUrl("logo") + "/" + linksData.invoice_logo.value}
        alt="logo"
        onError={addDefaultLogo}
      />
      <hr />

      <div>
        <p>{linksData.invoice_header_text.value}</p>
        <h4>Booking no.</h4>
        <p>{"#" + bookingId}</p>
        <p>{invoiceData.created ? invoiceData.created_full : ""}</p>
        <br />
        <h4>Customer</h4>
        <p>{invoiceData.customer ? invoiceData.customer.title : ""}</p>
        <p>{invoiceData.customer ? invoiceData.customer.phone : ""}</p>
        <p>
          {invoiceData.delivery ? invoiceData.delivery.delivery_address : ""}
        </p>
        <hr />
        <h4>Host</h4>
        <p>{invoiceData.host ? invoiceData.host.title : ""}</p>
        <p>{invoiceData.host ? invoiceData.host.phone : ""}</p>

        <hr />
        <h4>Booking</h4>
        <p>Starts - {invoiceData.starts ? invoiceData.starts_display : ""}</p>
        <p>Ends - {invoiceData.ends ? invoiceData.ends_display : ""}</p>

        {invoiceData?.product_id && (
          <>
            <hr />

            <h4>{getProductTitle()}</h4>
            <p> {invoiceData.product_title || ""}</p>
            <p> {invoiceData.product?.address || ""}</p>
          </>
        )}
      </div>

      <div style={{ borderTop: "0.5px solid #ccc",marginTop:'10px' }}>
      <h4>Payment Summary</h4>
        <table >
          {invoiceData.payment_summary ? (
            <tbody>
              {invoiceData.payment_summary.data.map((payment, ind) => {
                return payment.title !== "line" ? (
                  <tr className={payment.title + " payment"} key={ind}>
                    <td>{payment.title}</td>
                    <td></td>
                    <td style={{ textAlign: "right" }}>{payment.value}</td>
                  </tr>
                ) : (
                  <tr style={{ borderBottom: "1px solid #e2e2e2" }}>
                    <td
                      style={{
                        padding: "2px 0",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "2px 0",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "2px 0",
                      }}
                    ></td>
                  </tr>
                );
              })}

              <tr
                className="payment"
                style={{ borderTop: "1px solid #e2e2e2" }}
              >
                <td>Total ({invoiceData.gateway_display})</td>
                <td></td>
                <td style={{ textAlign: "right" }}>
                  {setting?.currency_symbol}
                  {invoiceData.payment_summary.total_display}
                </td>
              </tr>
            </tbody>
          ) : (
            ""
          )}
        </table>
      </div>
      <br />

      <p>Thank you for using the services of {setting?.project_name}.</p>
      <p>Powered by OnBooking</p>
      <br />
      <p>{linksData.invoice_footer_text.value}</p>
    </div>
  );
}
