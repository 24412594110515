import React, { useState } from "react";
import { ImgUrl, ApiService } from "../services";
import { TableContainer } from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GButton from "../gComponents/gButton";
import PluginForm from "../forms/webPluginForm";
import { useSnackbar } from "react-simple-snackbar";
import useDataFctory from "../useDataFactory";
import GText from "../gComponents/gText";
import Modal from "react-modal";

const columns = [
  { id: "title", label: "Title", minWidth: 120 },
  { id: "image", label: "Image", minWidth: 80, align: "center" },
  { id: "button_title", label: "Button Title", minWidth: 120, align: "center" },
  { id: "actions", label: "Actions", minWidth: 60, align: "center" },
];

export default function WebPlugins(props) {
  const [openSnackbar] = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedPluginData, setSelectedPluginData] = useState({});
  const [selectedPluginId, setSelectedPluginId] = useState("");
  const [webpvisible, setWebPvisible] = React.useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const {
    loading,
    data: pluginsData,
    Placeholder,
    TableShimmer,
  } = useDataFctory("web_plugins", false);

  const deletePlugin = () => {
    ApiService({
      method: "DELETE",
      route: "wp/homepage/" + selectedPluginId,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = pluginsData.findIndex(
            (p) => p.id == selectedPluginId
          );
          if (selectedIndex > -1) {
            pluginsData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="rightInnerView">
      <GButton
        variant="condensed"
        children="Add"
        onClick={() => {
          setSelectedPluginData({});
          setIsEmpty(true);
          setWebPvisible(true);
        }}
        style={{
          float: "right",
          marginTop: "5px",
        }}
      />
      <GText
        g2
        bold
        text={"Homepage Plugins"}
        style={{ marginBottom: "15px" }}
      />

      <div className="listPaper">
        {loading === true ? (
          <TableShimmer />
        ) : pluginsData.length === 0 ? (
          <Placeholder />
        ) : (
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableHeader
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.align,
                      }}
                    >
                      {column.label}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {pluginsData.map((c) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                      <TableRowData
                        style={{ BorderBottom: "1px solid #f4f5f9" }}
                      >
                        {c.title}
                      </TableRowData>
                      <TableRowData style={{ textAlign: "center" }}>
                        <img
                          alt={c.title}
                          src={ImgUrl("web") + "/" + c.thumb_image}
                          style={{
                            border: "1px solid #e2e2e2",
                            padding: "4px",
                            borderRadius: "4px",
                          }}
                        />
                      </TableRowData>
                      <TableRowData style={{ textAlign: "center" }}>
                        {c.b_title || "-"}
                      </TableRowData>
                      <TableRowData
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <GButton
                          variant="linkable"
                          onClick={(e) => {
                            setSelectedPluginData(c);
                            setIsEmpty(false);
                            setWebPvisible(true);
                          }}
                        >
                          <Edit fontSize="small" />
                        </GButton>
                        <GButton
                          variant="linkable"
                          onClick={(e) => {
                            setSelectedPluginId(c.id);
                            setDialogOpen(true);
                          }}
                        >
                          <Delete fontSize="small" />
                        </GButton>
                      </TableRowData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Plugin"
          text="Are you sure you want to delete this plugin?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deletePlugin();
          }}
        ></GConfirmDialog>
      )}
      <Modal
        isOpen={webpvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setWebPvisible(false)}
        style={{
          content: {
            width: "420px",
          },
        }}
        contentLabel="Website Plugin Form Modal"
      >
        <PluginForm
          webpvisible={webpvisible}
          setWebPvisible={setWebPvisible}
          data={selectedPluginData}
          isEmpty={isEmpty}
          onSubmit={(data) => {
            let selectedIndex = pluginsData.findIndex((p) => p.id == data.id);
            if (selectedIndex > -1) {
              pluginsData[selectedIndex] = { ...data };
            } else {
              pluginsData.push(data);
            }
          }}
        />
      </Modal>
    </div>
  );
}
