import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import Child from "./faqs";
import GText from "../gComponents/gText";

const faqRoutes = [
  {
    title: "customer",
    link: "/settings/faqs/customer",
  },
  {
    title: "host",
    link: "/settings/faqs/host",
    state: "setting",
  }
];

export default function FaqTypes({ match }) {
  const [typeValue, setTypeValue] = useState("customer");
  return (
    <div className="rightInnerView">
      <GText g2 bold text={"FAQ's"} style={{ marginBottom: "20px" }} />
      <GTabs routes={faqRoutes} active={typeValue}></GTabs>

      <Switch>
        <div className="listPaper">
          <Route path="/settings/faqs/:type">
            <Child
              onChange={(newValue) => {
                console.log(newValue);
                setTypeValue(newValue);
              }}
            />
          </Route>
        </div>
      </Switch>
    </div>
  );
}
