import React, { useState } from "react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import Dashboard from "./dashboard/dashboard";
import Bookings from "./bookings/ordersList";
import BookingDetail from "./bookings/orderDetails";
import OrderInvoice from "./bookings/orderInvoice";
import OrderThermalInvoice from "./bookings/orderThermalInvoice";
import Customers from "./customers/customersList";
import CustomerDetail from "./customers/customerDetails";
import Hosts from "./hosts/hostList";
import HostDetail from "./hosts/hostDetails";
import MapView from "./mapView/index";
import Reviews from "./components/reviewsList";
import Products from "./products/productsList";
import Coupons from "./components/couponsList";
import BulkNotifications from "./components/bulkPushNotifications";
import Reports from "./reports/reports";
import ReportDetails from "./reports/reportDetails";
import Payouts from "./components/payouts";
import Transactions from "./components/transactions";
import TransactionsFailed from "./components/transactionsFailed";
import SettingView from "./settings/settings";
import useSettings from "./useSettings";
import Login from "./Login";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import Sidebar from "./sidebar/sidebar";
import Header from "./header/header";
import Modal from "react-modal";
import GButton from "./gComponents/gButton";

const mainStyle = {
  display: "inline-flex",
  width: "100%",
};
const contentStyle = {
  background: "#f4f5f9",
  width: "82%",
  height: "100vh",
  overflow: "auto",
};

Modal.setAppElement("#root");
export default function App() {
  const location = useLocation();
  const setting = useSettings(true);
  const [toogleSidebar, setToggleSidebar] = useState(false);

  let match = useRouteMatch("/order-invoice/:id");
  let match2 = useRouteMatch("/order-thermal-invoice/:id");
  let url = match ? match.url : "";
  let tUrl = match2 ? match2.url : "";
  let settings = localStorage.getItem("settings")
    ? JSON.parse(localStorage.getItem("settings"))
    : localStorage.getItem("settings");

  const isLoggedIn = () => {
    if (
      localStorage.getItem("teamId") &&
      localStorage.getItem("access_token") &&
      localStorage.getItem("expires_in") > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  function isPermissionExist(per) {
    if (setting?.currency_symbol) {
      let exist = setting?.permissions?.includes(per);
      console.log(exist);
      return exist;
    } else {
      let exist = settings?.permissions?.includes(per);
      console.log(exist);
      return exist;
    }
  }

  return (
    <div>
      {isLoggedIn() &&
      !(url === location.pathname || tUrl === location.pathname) ? (
        <React.Fragment>
          <div style={mainStyle}>
            <Sidebar
              style={{
                width: toogleSidebar == true ? "0px" : "18%",
                minWidth: toogleSidebar == true ? "0px" : "165px",
                transition: "width 0.5s ,min-width 0.5s",
              }}
              toggle={toogleSidebar}
            />
            <div
              style={Object.assign({}, contentStyle, {
                width: toogleSidebar == true ? "100%" : "82%",
                transition: "width 0.5s",
              })}
            >
              <Header />

              <div
                className="mainInnerStyles"
                style={{
                  height: "calc(100vh - 54px)",
                  width: "100%",
                  overflow: "overlay",
                  overflow: "auto",
                  position: "relative",
                }}
              >
                {location.pathname == "/map-view" && (
                  <>
                    {toogleSidebar == true ? (
                      <GButton
                        variant="condensed"
                        style={{
                          position: "absolute",
                          zIndex: "8",
                          top: "0px",
                          padding: "0.2em",
                          left: "0px",
                          background: "#222326",
                          color: "#555",
                          border: "1px solid #101010",
                          borderRadius: "0",
                        }}
                        onClick={() => setToggleSidebar(false)}
                      >
                        <KeyboardArrowRight
                          style={{
                            width: "30px",
                            height: "30px",
                            marginleft: "3px",
                          }}
                        />
                      </GButton>
                    ) : (
                      <GButton
                        variant="condensed"
                        style={{
                          position: "absolute",
                          zIndex: "8",
                          top: "0px",
                          left: "0px",
                          padding: "0.2em",
                          background: "#222326",
                          color: "#777",
                          border: "1px solid #101010",
                          borderRadius: "0",
                        }}
                        onClick={() => setToggleSidebar(true)}
                      >
                        <KeyboardArrowLeft
                          style={{
                            width: "30px",
                            height: "30px",
                            marginleft: "3px",
                          }}
                        />
                      </GButton>
                    )}
                  </>
                )}
                {!(
                  settings?.user?.user_type === "sa" ||
                  settings?.user?.user_type === "admin"
                ) ? (
                  <Switch>
                    <Route path="/dashboard" component={Dashboard}></Route>
                    {isPermissionExist("order_index") && (
                      <Route path="/bookings" component={Bookings}></Route>
                    )}
                    {isPermissionExist("order_index") && (
                      <Route
                        path="/booking/:bookingId"
                        component={BookingDetail}
                      ></Route>
                    )}

                    {isPermissionExist("customer_index") && (
                      <Route path="/customers" component={Customers}></Route>
                    )}
                    {isPermissionExist("customer_index") && (
                      <Route
                        path="/customer/:customerId/:detailType"
                        component={CustomerDetail}
                      ></Route>
                    )}

                    {isPermissionExist("host_index") && (
                      <Route
                        path="/hosts"
                        state="host"
                        component={Hosts}
                      ></Route>
                    )}

                    {isPermissionExist("host_index") && (
                      <Route
                        path="/host/:hostId/:detailType"
                        state="host"
                        component={HostDetail}
                      ></Route>
                    )}
                    {isPermissionExist("host_index") && (
                      <Route
                        path="/map-view"
                        state="map"
                        component={MapView}
                      ></Route>
                    )}

                    {isPermissionExist("host_index") && (
                      <Route path="/reviews" component={Reviews}></Route>
                    )}
                    {isPermissionExist("product_index") && (
                      <Route path="/products" component={Products}></Route>
                    )}

                    {isPermissionExist("coupon_index") &&
                      isPermissionExist("promotion_bulk") && (
                        <Route
                          path="/bulk-notifications"
                          component={BulkNotifications}
                        ></Route>
                      )}
                    {isPermissionExist("coupon_index") && (
                      <Route path="/coupons" component={Coupons}></Route>
                    )}

                    {isPermissionExist("report_index") && (
                      <Route path="/reports" component={Reports}></Route>
                    )}
                    {isPermissionExist("report_index") && (
                      <Route
                        path="/report/details/:identifier"
                        component={ReportDetails}
                      ></Route>
                    )}

                    {isPermissionExist("transaction_index") && (
                      <Route
                        path="/transactions"
                        component={Transactions}
                      ></Route>
                    )}
                    <Redirect to="/dashboard" from="/" />
                    <Redirect to="/expires" from="/" />
                  </Switch>
                ) : (
                  <Switch>
                    <Route path="/dashboard" component={Dashboard}></Route>
                    <Route path="/bookings" component={Bookings}></Route>
                    <Route
                      path="/booking/:bookingId"
                      component={BookingDetail}
                    ></Route>

                    <Route path="/customers" component={Customers}></Route>
                    <Route
                      path="/customer/:customerId/:detailType"
                      component={CustomerDetail}
                    ></Route>
                    <Route path="/hosts" state="host" component={Hosts}></Route>
                    <Route
                      path="/host/:hostId/:detailType"
                      state="host"
                      component={HostDetail}
                    ></Route>
                    <Route
                      path="/map-view"
                      state="map"
                      component={MapView}
                    ></Route>
                    <Route path="/reviews" component={Reviews}></Route>
                    <Route path="/products" component={Products}></Route>
                    <Route
                      path="/bulk-notifications"
                      component={BulkNotifications}
                    ></Route>
                    <Route path="/coupons" component={Coupons}></Route>
                    <Route path="/reports" component={Reports}></Route>
                    <Route
                      path="/report/details/:identifier"
                      component={ReportDetails}
                    ></Route>

                    <Route
                      path="/transactions"
                      component={Transactions}
                    ></Route>
                    <Route
                      path="/failed-payments/:type"
                      component={TransactionsFailed}
                    ></Route>
                    <Route
                      path="/settings/:settingId"
                      component={SettingView}
                    />
                    <Route
                      path="/payouts"
                      component={Payouts}
                    />
                    <Redirect to="/dashboard" from="/" />
                  </Switch>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <>
          <Switch>
            {location?.pathname !== "/" && (
              <Redirect to="/" from={"/dashboard"} />
            )}
            <Route exact path="/" component={Login}></Route>
            <Route
              path="/order-invoice/:bookingId"
              component={OrderInvoice}
            ></Route>
            <Route
              path="/order-thermal-invoice/:bookingId"
              component={OrderThermalInvoice}
            ></Route>
          </Switch>
        </>
      )}
    </div>
  );
}
