import React, { useState, useEffect } from "react";
import { TableContainer } from "@material-ui/core";
import { ApiService, getUserType, isPermissionExist } from "../services";
import { Redeem, Edit, Delete } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import { Link, useHistory } from "react-router-dom";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import GSwitch from "../gComponents/gSwitch";
import CouponForm from "../forms/couponForm";
import GSelectbox from "../gComponents/gSelectbox";
import QueryWith from "../Query";
import { HostFilter } from "../components/hostFilterComponent";
import { useSnackbar } from "react-simple-snackbar";
import Modal from "react-modal";

const statusFilters = [
  {
    title: "Active",
    value: "1",
  },
  {
    title: "Inactive",
    value: "0",
  },
];

export default function Coupons() {
  const [openSnackbar] = useSnackbar();
  const [couponData, setCouponData] = useState([]);
  const [couponPaginationData, setCouponPaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCouponData, setSelectedCouponData] = useState({
    index: "",
    data: {},
  });
  const [selectedCouponId, setSelectedCouponId] = useState("");
  const [ccpnvisible, setCcpnvisible] = useState(false);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    status: params.get("status") ? params.get("status") : "",
    host_id: params.get("host_id") ? params.get("host_id") : "",
  });

  const columns = [
    { id: "title", label: "Title", minWidth: 130 },
    { id: "discount", label: "Discount", minWidth: 50 },
    { id: "coupon_code", label: "Coupon Code", minWidth: 120 },
    { id: "host", label: "Host", minWidth: 120 },
    { id: "time_schedule", label: "Time Schedule", minWidth: 150 },
    { id: "status", label: "Status", minWidth: 50 },
    getUserType() ||
    isPermissionExist("coupon_update") ||
    isPermissionExist("coupon_destroy")
      ? { id: "actions", label: "Actions", minWidth: 60, align: "center" }
      : "",
  ];

  const getCoupons = () => {
    var params = QueryWith(history.location, filters);
    if (params.length > 0) {
      history.push({
        pathname: "/coupons",
        state: "coupon",
        search: params[0],
      });
    }
    ApiService({ method: "GET", route: "coupons", body: filters })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          setCouponData(response.data.data.data);
          setCouponPaginationData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCoupons();
  }, [history, filters]);

  const deleteCoupon = () => {
    ApiService({ method: "DELETE", route: "coupons/" + selectedCouponId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getCoupons();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toggleValue = (newValue, valueId, i) => {
    ApiService({
      method: "PUT",
      route: "coupons/" + valueId,
      body: { status: newValue },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          couponData[i].status = newValue;
          setCouponData([...couponData]);
        } else {
          getCoupons();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  return (
    <div className="mainInnerView">
      <h1>
        <Redeem /> Coupons
      </h1>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          marginBottom: "15px",
          gap: "10px",
        }}
      >
        <HostFilter
          value={filters.host_id}
          handleHostChange={(value) => {
            setFilters({ ...filters, host_id: value || "", page: 1 });
          }}
        />
        <GSelectbox
          placeholder="Select Status"
          defaultvalue={filters.status}
          data={statusFilters}
          id="item_status_filter"
          option_key="title"
          option_value="value"
          onChange={(newVal) => {
            setFilters({ ...filters, status: newVal, page: 1 });
          }}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            minWidth: "150px",
          }}
        />
        {couponData.length > 0 && (
          <>
            <GPagination
              style={{ marginLeft: "auto" }}
              count={couponPaginationData.total}
              from={couponPaginationData.from || 0}
              to={couponPaginationData.to || 0}
              onChangeNextPage={() =>
                handleChangePage(couponPaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(couponPaginationData.prev_page_url)
              }
              rowsPerPage={couponPaginationData.per_page}
              prevDisabled={couponPaginationData.prev_page_url ? false : true}
              nextDisabled={couponPaginationData.next_page_url ? false : true}
            ></GPagination>
            <GPaginationSelect
              defaultvalue={filters.page}
              totalPage={couponPaginationData.last_page}
              onChangePage={(NewPageNo) =>
                setFilters({ ...filters, page: NewPageNo })
              }
            />
          </>
        )}
      </div>

      <div className="listPaper">
        {!couponPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer>
            {couponData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {couponData.map((c, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData
                          style={{
                            BorderBottom: "1px solid #f4f5f9",
                          }}
                        >
                          {c.title}
                        </TableRowData>
                        <TableRowData> {c.discount}% </TableRowData>
                        <TableRowData>{c.code}</TableRowData>
                        <TableRowData>
                          {c.host_id ? (
                            <Link
                              to={{
                                pathname: "/host/" + c.host_id + "/bookings",
                                state: "host",
                              }}
                            >
                              {c.host_name}
                            </Link>
                          ) : (
                            "-"
                          )}
                        </TableRowData>
                        <TableRowData>
                          Valid From :{" "}
                          {c.valid_from ? (
                            c.valid_from
                          ) : (
                            <span style={{ color: "#a2a2a2" }}>NA</span>
                          )}
                          <br />
                          Valid Upto :{" "}
                          {c.expiry ? (
                            c.expiry
                          ) : (
                            <span style={{ color: "#a2a2a2" }}>NA</span>
                          )}
                        </TableRowData>
                        <TableRowData>
                          <GSwitch
                            id={c.id}
                            uId={c.id}
                            disabled={
                              getUserType() ||
                              isPermissionExist("coupon_update")
                                ? false
                                : true
                            }
                            currentValue={
                              c.status === "true" || c.status === "1" ? 1 : 0
                            }
                            onSwitchChange={(newVal, id) =>
                              toggleValue(newVal, id, index)
                            }
                          />
                        </TableRowData>
                        {getUserType() ||
                        isPermissionExist("coupon_update") ||
                        isPermissionExist("coupon_destroy") ? (
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {getUserType() ||
                            isPermissionExist("coupon_update") ? (
                              <GButton
                                variant="linkable"
                                onClick={(e) => {
                                  setSelectedCouponData({
                                    index: index,
                                    data: c,
                                  });
                                  setCcpnvisible(true);
                                }}
                              >
                                <Edit fontSize="small" />
                              </GButton>
                            ) : (
                              ""
                            )}

                            {getUserType() ||
                            isPermissionExist("coupon_destroy") ? (
                              <GButton
                                variant="linkable"
                                onClick={(e) => {
                                  setSelectedCouponId(c.id);
                                  setDialogOpen(true);
                                }}
                              >
                                <Delete fontSize="small" />
                              </GButton>
                            ) : (
                              ""
                            )}
                          </TableRowData>
                        ) : (
                          ""
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Coupon"
          text="Are you sure you want to delete this coupon?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteCoupon();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={ccpnvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCcpnvisible(false)}
        style={{
          content: {
            width: "500px",
          },
        }}
        contentLabel="Coupon Form Modal"
      >
        <CouponForm
          ccpnvisible={ccpnvisible}
          setCcpnvisible={setCcpnvisible}
          data={selectedCouponData.data}
          onSubmit={(data) => {
            console.log(data);
            if (data) {
              couponData[selectedCouponData.index] = data;
            }
          }}
        />
      </Modal>
    </div>
  );
}
