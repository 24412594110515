import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import GEditable from "../gComponents/gEditable";
import { useSnackbar } from "react-simple-snackbar";
import GSwitch from "../gComponents/gSwitch"; 
import GText from "../gComponents/gText";

export default function AppSettings(props) {
  const [openSnackbar] = useSnackbar(); 
  const [appData, setAppData] = useState({
    i_c_url: {},
    a_c_url: {}, 
    delivery_tip: {},
    signup_name: {},
  });

  useEffect(() => {
    getSocialSettings();
  }, []);

  const getSocialSettings = () => {
    ApiService({ method: "GET", route: "setting/app" })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          setAppData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function updateField(newValue, selectedFieldId, field) {
    console.log(newValue);
    console.log(selectedFieldId);
    // return;
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        value: newValue,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          appData[field] = response.data.data;
        } else {
        }
        openSnackbar(response.data.message);
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText g2 bold text={"App Settings"} style={{ marginBottom: "20px" }} />

      <div className="listPaper">
        <div
          style={{
            display: "inline-block",
            width: "60%",
            minWidth: "540px",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <GEditable
            key={appData.i_c_url.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={appData.i_c_url.value}
            className={props.display}
            placeholder={appData.i_c_url.title}
            id={appData.i_c_url.id}
            onSubmit={(newValue, selectedFieldId) =>
              updateField(newValue, selectedFieldId, "i_c_url")
            }
            style={{ margin: "10px 0 20px" }}
            required={false}
          />

          <GEditable
            key={appData.a_c_url.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={appData.a_c_url.value}
            className={props.display}
            placeholder={appData.a_c_url.title}
            id={appData.a_c_url.id}
            onSubmit={(newValue, selectedFieldId) =>
              updateField(newValue, selectedFieldId, "a_c_url")
            }
            style={{ margin: "10px 0 20px" }}
            required={false}
          />

          {appData.signup_name && (
            <div style={{ display: "flex", padding: "15px 0" }}>
              <label>{appData.signup_name.title}</label>
              <div style={{ width: "calc(100% - 320px)" }}>
                <GSwitch
                  id={appData.signup_name.id}
                  uId={appData.signup_name.id}
                  currentValue={appData.signup_name.value}
                  style={{ margin: "0 20px" }}
                  onSwitchChange={(newVal, id) =>
                    updateField(newVal, id, "signup_name")
                  }
                />
              </div>
            </div>
          )}
  
          {appData.delivery_tip && (
            <div style={{ display: "flex", padding: "15px 0" }}>
              <label>{appData.delivery_tip.title}</label>
              <div style={{ width: "calc(100% - 320px)" }}>
                <GSwitch
                  id={appData.delivery_tip.id}
                  uId={appData.delivery_tip.id}
                  currentValue={appData.delivery_tip.value}
                  style={{ margin: "0 20px" }}
                  onSwitchChange={(newVal, id) =>
                    updateField(newVal, id, "delivery_tip")
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
