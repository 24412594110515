import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ApiService } from "../services";
import { TableContainer } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { Edit, Delete, Add } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GButton from "../gComponents/gButton";
import CancelReasonForm from "../forms/CancelReasonForm";
import useDataFctory from "../useDataFactory";
import Modal from "react-modal";

const columns = [
  { id: "title", label: "Title", minWidth: 200 },
  { id: "actions", label: "Actions", minWidth: 60, align: "center" },
];

export default function Child(props) {
  let { type } = useParams();
  var typeId = type === "customer" ? "1" : type === "host" ? "2" : "";

  const [openSnackbar] = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState({});
  const [selectedReasonId, setSelectedReasonId] = useState("");
  const [crvisible, setCrvisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const {
    loading,
    Placeholder,
    data: cancelReasonsData,
    TableShimmer,
    refreshData,
  } = useDataFctory("cancel_reasons", false, { user_type: typeId });

  useEffect(() => {
    if (initialLoading == false) {
      refreshData({ user_type: typeId });
      props.onChange(type);
    }

    setInitialLoading(false);
  }, [type]);

  const deleteReason = () => {
    ApiService({
      method: "DELETE",
      route: "cancel-reasons/" + selectedReasonId,
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          let selectedIndex = cancelReasonsData.findIndex(
            (p) => p.id == selectedReasonId
          );
          if (selectedIndex > -1) {
            cancelReasonsData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <GButton
        variant="linkable"
        children="Add"
        onClick={() => {
          setSelectedReason({});
          setIsEmpty(true);
          setCrvisible(true);
        }}
        style={{
          margin: "-60px -15px 0px",
          float: "right",
        }}
      >
        <Add fontSize="large" />
      </GButton>

      {loading === true ? (
        <TableShimmer />
      ) : (
        <TableContainer>
          {cancelReasonsData.length === 0 ? (
            <Placeholder />
          ) : (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableHeader
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.align,
                      }}
                    >
                      {column.label}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {cancelReasonsData.map((c) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                      <TableRowData
                        style={{
                          BorderBottom: "1px solid #f4f5f9",
                        }}
                      >
                        {c.title}
                      </TableRowData>
                      <TableRowData
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <GButton
                          variant="linkable"
                          children="Add"
                          onClick={() => {
                            setSelectedReason(c);
                            setIsEmpty(false);
                            setCrvisible(true);
                          }}
                        >
                          <Edit fontSize="small" />
                        </GButton>

                        <GButton
                          variant="linkable"
                          onClick={(e) => {
                            setSelectedReasonId(c.id);
                            setDialogOpen(true);
                          }}
                        >
                          <Delete fontSize="small" />
                        </GButton>
                      </TableRowData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      )}

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Cancel Reason"
          text="Are you sure you want to delete this cancel reason?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteReason();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={crvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCrvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Cancel Reason Form Modal"
      >
        <CancelReasonForm
          crvisible={crvisible}
          setCrvisible={setCrvisible}
          type={typeId}
          data={selectedReason}
          isEmpty={isEmpty}
          onSubmit={(data) => {
            let selectedIndex = cancelReasonsData.findIndex(
              (p) => p.id == data.id
            );
            if (selectedIndex > -1) {
              cancelReasonsData[selectedIndex] = { ...data };
            } else {
              cancelReasonsData.push(data);
            }
          }}
        />
      </Modal>
    </div>
  );
}
