import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles((theme) => ({
  root: {
    float: "right",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Ul = styled.ul`
  display: inline-flex;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0;
  list-style-type: none;
  margin: 0px;
  padding-right: 10px;
`;
const Li = styled.li`
  color: #a4a5a9;
  padding: 7px 3px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  & a {
    color: #a4a5a9;
  }
  & .MuiSvgIcon-fontSizeSmall {
  }
  & p {
    margin: 0;
    font-size: 15px;
    color: #555;
  }
`;

export default function CustomSeparator(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Ul>
        <Li>
          <Link color="inherit" to="/dashboard">
            Home
          </Link>
        </Li>
        <Li>
          <NavigateNextIcon fontSize="small" />
        </Li>
        {props.links.map((s, i) => {
          return (
            <React.Fragment key={i}>
              <Li>
                <Link color="inherit" to={{ pathname: s.link, state: s.state }}>
                  {s.title}
                </Link>
              </Li>
              <Li>
                <NavigateNextIcon fontSize="small" />
              </Li>
            </React.Fragment>
          );
        })}
        <Li>
          <p color="textPrimary">{props.currentPage}</p>
        </Li>
      </Ul>
    </div>
  );
}
