import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function GdatePicker(props) {
  const { label, name, id, value, format,minDate, ...rest } = props;

  return (
    <React.Fragment>
      {label ? <label htmlFor={name}>{label}</label> : ""}
     <DatePicker
        id={id}
        selected={value}
        dateFormat={format}
        onChange={(date) => props.onChange(date)}
        minDate={minDate || null}
      />
    </React.Fragment>
  );
}

export default GdatePicker;
