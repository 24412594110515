import React from "react";
import styled from "styled-components";
import { Skeleton } from "@material-ui/lab";

const Shimmer = styled.div`
  flex: 1;
  display: flex;
  box-sizing: border-box;
  .MuiSkeleton-root {
    width: 100%;
    background: #f6f7f8;

    &.MuiSkeleton-rect {
      margin: 0 8px 25px;
      display: inline-block;
      box-sizing: border-box;
    }
    &.MuiSkeleton-text {
      margin-top: 10px;
      margin-bottom: 40px;
    }
  }
  &.dark {
    .MuiSkeleton-root {
      width: 100%;
      background: #222;

      &.MuiSkeleton-rect {
        margin: 0 8px 25px;
        display: inline-block;
        box-sizing: border-box;
      }
      &.MuiSkeleton-text {
        margin-top: 10px;
        margin-bottom: 40px;
      }
    }
  }
`;

const TShimmer = styled.div`
  flex: 1;
  display: flex;
  box-sizing: border-box;
  &.dark {
    .MuiSkeleton-root {
      width: 100%;
      background: #222;

      &.MuiSkeleton-rect {
        margin: 0 8px 8px;
        display: inline-block;
        box-sizing: border-box;
      }
      &.MuiSkeleton-text {
        margin-top: 10px;
        margin-bottom: 40px;
      }
    }
  }
`;

const CardShimmer = styled.div`
  div {
    display: inline-block;
    width: 32%;
    box-sizing: border-box;
    padding: 10px;
    flex: 1;
    flex-direction: row;
    .MuiSkeleton-root {
      width: 100%;
      background: #f6f7f8;

      &.MuiSkeleton-rect {
        margin-top: 0px;
        margin-bottom: 10px;
      }
      &.MuiSkeleton-text {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
`;

export function GHorCardShimmer(props) {
  return (
    <Shimmer className={props.theme || ""} style={{ flexDirection: "column" }}>
      <Skeleton
        variant="rect"
        height={props.height || 80}
        animation="wave"
        style={{ width: "95%" }}
      />
      <Skeleton
        variant="rect"
        height={props.height || 80}
        animation="wave"
        style={{ width: "95%" }}
      />
      <Skeleton
        variant="rect"
        height={props.height || 80}
        animation="wave"
        style={{ width: "95%" }}
      />
    </Shimmer>
  );
}

export function GTaskShimmer(props) {
  return (
    <TShimmer className={props.theme || ""} style={{ flexDirection: "column" }}>
      <Skeleton
        variant="rect"
        height={props.height || 20}
        animation="wave"
        style={{
          width: "40%",
          borderRadius: "20px",
          margin: "10px 0 10px 25px",
        }}
      />
      <div
        style={{ display: "flex", alignItems: "center", margin: "10px 25px" }}
      >
        <Skeleton
          variant="rect"
          height={100}
          animation="wave"
          style={{ width: "8px" }}
        />
        <div style={{ width: "80%" }}>
          <Skeleton
            variant="rect"
            height={15}
            animation="wave"
            style={{ width: "35%" }}
          />
          <Skeleton
            variant="rect"
            height={15}
            animation="wave"
            style={{ width: "85%" }}
          />
          <Skeleton
            variant="rect"
            height={0}
            style={{ width: "80%", background: "transparnt", display: "block" }}
          />
          <Skeleton
            variant="rect"
            height={15}
            animation="wave"
            style={{ width: "35%" }}
          />
          <Skeleton
            variant="rect"
            height={15}
            animation="wave"
            style={{ width: "85%" }}
          />
        </div>
      </div>
    </TShimmer>
  );
}
export function GSingleShimmer(props) {
  return (
    <TShimmer className={props.theme || ""} style={{ flexDirection: "column" }}>
      <Skeleton
        variant="rect"
        height={30}
        animation="wave"
        style={{ width: "95%", borderRadius: "4px" }}
      />
    </TShimmer>
  );
}

export function GMapShimmer(props) {
  let array = [1, 2, 3, 4, 5, 6, 7];
  return (
    <>
      <TShimmer className={props.theme || ""} style={{ flexDirection: "row" }}>
        {array.map((a, i) => {
          return (
            <Skeleton
              key={i}
              variant="rect"
              height={200}
              animation="wave"
              style={{
                width: "33%",
                margin: "0",
                background: a % 2 == 0 ? "#1C1C20" : "#16181A",
              }}
            />
          );
        })}
      </TShimmer>
      <TShimmer className={props.theme || ""} style={{ flexDirection: "row" }}>
        {array.map((a, i) => {
          return (
            <Skeleton
              key={i}
              variant="rect"
              height={200}
              animation="wave"
              style={{
                width: "33%",
                margin: "0",
                background: a % 2 == 0 ? "#16181A" : "#1C1C20",
              }}
            />
          );
        })}
      </TShimmer>
    </>
  );
}

export function GCardShimmer() {
  return (
    <Shimmer>
      <Skeleton variant="rect" height={80} animation="wave" />
      <Skeleton variant="rect" height={80} animation="wave" />
      <Skeleton variant="rect" height={80} animation="wave" />
    </Shimmer>
  );
}

export function GShimmer() {
  return (
    <CardShimmer>
      <div>
        <Skeleton variant="text" height={90} animation="wave" />
        <Skeleton variant="rect" height={20} animation="wave" />
        <Skeleton variant="rect" height={20} animation="wave" />
      </div>
      <div>
        <Skeleton variant="text" height={90} animation="wave" />
        <Skeleton variant="rect" height={20} animation="wave" />
        <Skeleton variant="rect" height={20} animation="wave" />
      </div>
      <div>
        <Skeleton variant="text" height={90} animation="wave" />
        <Skeleton variant="rect" height={20} animation="wave" />
        <Skeleton variant="rect" height={20} animation="wave" />
      </div>
    </CardShimmer>
  );
}
