import orderIcon from "../assets/images/sidebar/orders.svg";
import customerIcon from "../assets/images/sidebar/customers.svg";
import driverIcon from "../assets/images/sidebar/drivers.svg";
import mapIcon from "../assets/images/sidebar/map.svg";
import productIcon from "../assets/images/sidebar/products.svg";
import couponIcon from "../assets/images/sidebar/promotions.svg";
import withdrawalIcon from "../assets/images/sidebar/withdrawals.svg";
import transactionIcon from "../assets/images/sidebar/transactions.svg";
import reportIcon from "../assets/images/sidebar/reports.svg";
import notificationIcon from "../assets/images/sidebar/notifications.svg";
import { getUserType,getProductTitle } from "../services";

export const menus = [
  {
    icon: orderIcon,
    title: "Bookings",
    path: "/bookings",
    state: "booking",
    isLink: true,
    isStaff: "order_index",
  },
  {
    icon: customerIcon,
    title: "Customers",
    path: "/customers",
    state: "customer",
    isLink: true,
    isAdd: "customer_store",
    isStaff: "customer_index",
  },
  {
    icon: driverIcon,
    title: "Hosts",
    path: "/hosts",
    state: "host",
    isLink: true,
    isAdd: "host_store",
    isStaff: "host_index",
  },
  {
    icon: mapIcon,
    title: "Map View",
    path: "/map-view",
    state: "map",
    isLink: true,
    isAdd: false,
    isStaff: "product_index",
  },
  {
    icon: productIcon,
    title: getProductTitle(),
    path: "/products",
    state: "product",
    isLink: true,
    isStaff: "product_index",
    children: getUserType()
    ? [
        {
          icon: "📋",
          title: "Reviews",
          path: "/reviews",
          state: "product",
          isLink: true,
        },
      ]
    : null,
  },
  {
    icon: couponIcon,
    title: "Promotions",
    path: "/coupons",
    state: "coupon",
    isLink: true,
    isAdd: "coupon_store",
    isStaff: "coupon_index",
    children: getUserType()
      ? [
          {
            icon: "📋",
            title: "Bulk Notifications",
            path: "/bulk-notifications",
            state: "coupon",
            isLink: true,
          },
        ]
      : null,
  },
  !getUserType()
    ? {
        icon: notificationIcon,
        title: "Bulk Notifications",
        path: "/bulk-notifications",
        state: "bulk",
        isLink: true,
        isStaff: "promotion_bulk",
      }
    : "",
  {
    icon: withdrawalIcon,
    title: "Payouts",
    state: "payout",
    isLink: true,
    path: "/payouts",
  },
  {
    icon: transactionIcon,
    title: "Transactions",
    path: "/transactions",
    state: "transaction",
    isLink: true,
    children: [
      {
        icon: "📋",
        title: "Failed Payments",
        path: "/failed-payments/pending",
        state: "transaction",
        isLink: true,
      },
    ],
  },
  {
    icon: reportIcon,
    title: "Reports",
    path: "/reports",
    state: "report",
    isLink: true,
  },
];
