import React from "react";
import { Field, ErrorMessage } from "formik";
import TextErrors from "./formikTextErrors";

const radioLabelStyle = {
  display: "block",
  padding: "0 15px 5px 5px",
  margin: "0",
};
function checkBoxes(props) {
  const { label, name, key_title, key_value, options, ...rest } = props;
  return (
    <div className="form-control">
      <label>{label}</label>
      <Field name={name} {...rest}>
        {({ field }) => {
          return options.map((option, index) => {
            return (
              <div key={index} style={{ display: "flex" }}>
                <input
                  type="checkbox"
                  id={option[key_value]}
                  {...field}
                  value={option[key_value]}
                  checked={
                    typeof option[key_value] == "string"
                      ? field.value.includes(option[key_value])
                      : field.value.includes(JSON.stringify(option[key_value]))
                  }
                />
                <label style={radioLabelStyle} htmlFor={option[key_value]}>
                  {option[key_title]}
                </label>
              </div>
            );
          });
        }}
      </Field>
      <ErrorMessage name={name} component={TextErrors} />
    </div>
  );
}

export default checkBoxes;
