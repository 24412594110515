import React, { useState } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";

export default function RolesForm(props) {
  const [openSnackbar] = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);

  const initialValues = {
    name: !props.isEmpty ? props.data.name : "",
  };

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);

    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "roles/" + props.data.id : "roles";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log("r" + response.data.status_code);
        if (response.data.status_code === 1) {
          props.setRoleVisible(false);
          props.onSubmit(response.data.data);
        }
        openSnackbar(response.data.message);
        setIsDisabled(false);
      }
    );
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
  });

  if (props.roleVisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? "Edit" : "Create"} Role
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <FormikControl
              control="input"
              type="text"
              label="Name"
              name="name"
            />
            <br />
            <GButton
              variant="condensed"
              disabled={isDisabled}
              children={!props.isEmpty ? "Update" : "Create"}
              type="submit"
              style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
            />
          </Form>
        </Formik>
      </div>
    </>
  );
}
