import React, { useEffect, useState } from "react";
import { ApiService, getUserType, isPermissionExist } from "../services";
import RightListView from "./listview";
import { withScriptjs } from "react-google-maps";
import MapLoader from "./map";
import GButton from "../gComponents/gButton";
import styled from "styled-components";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@material-ui/icons";
import { GHorCardShimmer, GMapShimmer } from "../gComponents/gCardShimmer";
import GSwitch from "../gComponents/gSwitch";
import useSettings from "../useSettings";
import { GMultiSelectbox } from "../gComponents/gMultiSelectbox";

const Div = styled.div`
  width: 100%; 
  transition: width 0.5s;position:relative;

  &.toggle {
    width: calc(100% - 420px); 
`;

const driverTypes = [
  {
    type: 1,
    title: "In transit",
    color: "#43829B",
  },
  {
    type: 2,
    title: "Idle",
    color: "#76A543",
  },
  {
    type: 3,
    title: "Offline",
    color: "#9D9E9D",
  },
];

const loadGoogleMapScript = (callback) => {
  if (
    typeof window.google === "object" &&
    typeof window.google.maps === "object"
  ) {
    callback();
  } else {
    const googleMapScript = document.createElement("script");
    googleMapScript.src =
      `https://maps.googleapis.com/maps/api/js?key=` +
      process.env.REACT_APP_GOOGLE_KEY +
      `&libraries=geometry`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener("load", callback);
  }
};

export default function DriverMapView() {
  // const MapLoader = withScriptjs(Map);

  const settings = useSettings();
  const [isLoading, setIsLoading] = useState(true);
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [driversData, setDriversData] = useState([]);
  const [listView, setListview] = useState(false);
  const [locationsData, setlocationsData] = useState({
    drivers: [],
  });
  const [updatedDriver, setUpdatedDriver] = useState({});
  const [isLocateDriver, setIsLocateDriver] = useState(false);
  const [loadMap, setLoadMap] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState({});

  const getMapLists = () => {
    ApiService({ method: "OPTIONS", route: "map" })
      .then((response) => {
        console.log(response);

        setDriversData(response.data.data.products || []);
        setIsLoading(false);
        setIsMapLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
    if (getUserType() || isPermissionExist("product_index")) {
      getMapLists();
    }
  }, []);

  useEffect(() => {
    if (isMapLoading == false) {
      setlocationsData({
        drivers: driversData.filter((d) => d.lat),
      });
    }
  }, [driversData, isMapLoading]);

  return (
    <>
      <div style={{ background: "#151515", display: "flex" }}>
        <Div
          className={listView == true ? "toggle" : ""}
          style={{
            // width: isTaskView == true ? "calc(100% - 300px)" : "100%",
            display: "inline-block",
          }}
        >
          {isLoading === true ? (
            <div style={{ height: "calc(100vh - 57px)", overflow: "hidden" }}>
              <GMapShimmer theme="dark"></GMapShimmer>
              <GMapShimmer theme="dark"></GMapShimmer>
            </div>
          ) : (
            <>
              {!loadMap ? (
                <div>Loading...</div>
              ) : (
                <MapLoader
                  locationsData={locationsData}
                  driversLocations={driversData.filter((d) => d.lat) || []}
                  updatedDriver={updatedDriver}
                  chooseDriver={selectedDriver}
                  isLocateDriver={isLocateDriver}
                  setIsLocateDriver={setIsLocateDriver}
                />
              )}

              <GButton
                variant="condensed"
                onClick={() => {
                  setListview(!listView);
                }}
                style={{
                  position: "absolute",
                  zIndex: "9",
                  top: "0px",
                  right: "0",
                  border: "1px solid #101010",
                  borderRight: "0",
                  borderRadius: "0",
                  padding: "10px 14px",
                  background: "#222326",
                  color: "#777",
                  padding: "0.2em",
                }}
              >
                {listView == true ? (
                  <KeyboardArrowRight
                    style={{
                      width: "30px",
                      height: "30px",
                      marginLeft: "3px",
                    }}
                  />
                ) : (
                  <KeyboardArrowLeft
                    style={{
                      width: "30px",
                      height: "30px",
                      marginLeft: "3px",
                    }}
                  />
                )}
              </GButton>
            </>
          )}
        </Div>

        {listView == true ? (
          <RightListView
            setListview={setListview}
            listView={listView}
            settings={settings}
            updatedDriver={updatedDriver}
            driversData={driversData}
            onLocateDriver={(d) => {
              console.log(d);
              setSelectedDriver(d);
              setIsLocateDriver(true);
            }}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}
