import React, { useState, useEffect } from "react";
import { TableContainer, Avatar } from "@material-ui/core";
import {
  ApiService,
  ImgUrl,
  getUserType,
  isPermissionExist,
} from "../services";
import { PeopleAlt, Edit, Delete, Block } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import { Link, useHistory, useLocation } from "react-router-dom";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import { useSnackbar } from "react-simple-snackbar";
import HostForm from "../forms/hostForm";
import TooltipComponent from "../gComponents/gTooltip";
import QueryWith from "../Query";
import { GSearch } from "../gComponents/gSearch";
import GSelectbox from "../gComponents/gSelectbox";
import useSettings from "../useSettings";
import { ReactComponent as Unblock } from "../assets/images/unlock.svg";
import Modal from "react-modal";

const hostStatusFilters = [
  {
    title: "Available Only",
    value: "1",
  },
  {
    title: "Unavailable Only",
    value: "0",
  },
];

const statusFilters = [
  {
    title: "Active Hosts",
    value: "active",
  },
  {
    title: "Blocked Hosts",
    value: "inactive",
  },
];

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Hosts() {
  const [openSnackbar] = useSnackbar();
  const [initialLoading, setInitialLoading] = useState(true);
  const [chvisible, setChvisible] = useState(false);
  const [hostData, setHostData] = useState([]);
  const [hostPaginationData, setHostPaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [blockDialogOpen, setBlockDialogOpen] = useState(false);
  const [selectedStatusType, setSelectedStatusType] = useState();
  const [selectedHostData, setSelectedHostData] = useState({});
  const [selectedHostId, setSelectedHostId] = useState("");
  const settings = useSettings(true);
  let history = useHistory();
  let query = useQuery();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    search: params.get("search") ? params.get("search") : "",
    host_status: params.get("host_status") ? params.get("host_status") : "",
    status: params.get("status") ? params.get("status") : "active",
  });

  const columns = [
    { id: "first_name", label: "Name", minWidth: 130 },
    { id: "photo", label: "Photo", minWidth: 50, type: "image" },
    { id: "email", label: "Email", minWidth: 150 },
    { id: "phone", label: "Mobile", minWidth: 120 },
    {
      id: "wallet_points_value",
      label: "Balance",
      minWidth: 90,
      // format: (value) => value.toLocaleString('en-US'),
      format: (value) => value.toFixed(2),
    },
    { id: "created_at_formatted", label: "Created", minWidth: 150 },
    getUserType() == true ||
    isPermissionExist("host_update") ||
    isPermissionExist("host_destroy")
      ? { id: "actions", label: "Actions", minWidth: 60, align: "center" }
      : "",
  ];

  const getHosts = () => {
    var params = QueryWith(history.location, filters);
    if (params.length > 0) {
      history.push({
        pathname: "/hosts",
        state: "host",
        search: params[0],
      });
    }
    ApiService({ method: "GET", route: "hosts", body: filters })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          setHostData(response.data.data.data);
          setHostPaginationData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (initialLoading == false) {
      getHosts();
    }
  }, [filters]);

  useEffect(() => {
    console.log(query);
    setFilters({
      page: query.get("page") ? query.get("page") : "1",
      search: query.get("search") ? query.get("search") : "",
      host_status: query.get("host_status") ? query.get("host_status") : "",
      status: query.get("status") ? query.get("status") : "active",
    });
    setInitialLoading(false);
  }, [query]);

  const deleteHost = () => {
    ApiService({ method: "DELETE", route: "hosts/" + selectedHostId })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          getHosts();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const blockHost = () => {
    var hitUrl =
      selectedStatusType == "Block"
        ? "host/block/" + selectedHostId
        : "host/unblock/" + selectedHostId;

    ApiService({ method: "POST", route: hitUrl })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          getHosts();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  return (
    <div className="mainInnerView">
      <h1>
        <PeopleAlt /> Hosts
      </h1>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          marginBottom: "15px",
          gap: "10px",
        }}
      >
        <GSearch
          placeholder="Search by name, email or phone.."
          value={filters.search}
          handleChange={(val) =>
            setFilters({ ...filters, search: val, page: 1 })
          }
        />
        <GSelectbox
          showSelectOption={false}
          defaultvalue={filters.status}
          data={statusFilters}
          id="d_status_filter"
          option_key="title"
          option_value="value"
          onChange={(newVal) => {
            setFilters({ ...filters, status: newVal, page: 1 });
          }}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            minWidth: "150px",
          }}
        />
        <GSelectbox
          placeholder="All Hosts"
          defaultvalue={filters.host_status}
          data={hostStatusFilters}
          id="host_status_filter"
          option_key="title"
          option_value="value"
          onChange={(newVal) => {
            setFilters({ ...filters, host_status: newVal, page: 1 });
          }}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            minWidth: "150px",
          }}
        />

        {hostData.length > 0 ? (
          <React.Fragment>
            <GPagination
              style={{ marginLeft: "auto" }}
              count={hostPaginationData.total}
              from={hostPaginationData.from}
              to={hostPaginationData.to}
              onChangeNextPage={() =>
                handleChangePage(hostPaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(hostPaginationData.prev_page_url)
              }
              rowsPerPage={hostPaginationData.per_page}
              prevDisabled={hostPaginationData.prev_page_url ? false : true}
              nextDisabled={hostPaginationData.next_page_url ? false : true}
            ></GPagination>
            <GPaginationSelect
              defaultvalue={filters.page}
              totalPage={hostPaginationData.last_page}
              onChangePage={(NewPageNo) =>
                setFilters({ ...filters, page: NewPageNo })
              }
            />
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
      <div className="listPaper">
        {!hostPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer>
            {hostData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hostData.map((c) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData
                          style={{
                            BorderBottom: "1px solid #f4f5f9",
                          }}
                        >
                          <Link
                            to={{
                              pathname: "/host/" + c.id + "/bookings",
                              state: "host",
                            }}
                          >
                            {c.first_name} {c.last_name}
                          </Link>
                        </TableRowData>
                        <TableRowData>
                          <Avatar
                            alt={c.first_name}
                            src={ImgUrl("host") + "/" + c.thumb_photo}
                          />
                        </TableRowData>
                        <TableRowData>{c.email}</TableRowData>
                        <TableRowData>{c.phone}</TableRowData>
                        <TableRowData>
                          {settings.currency_symbol + "" + c.balance}
                        </TableRowData>
                        <TableRowData>{c.created}</TableRowData>
                        {getUserType() == true ||
                        isPermissionExist("host_update") ||
                        isPermissionExist("host_destroy") ? (
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {getUserType() == true && c.status === 0 && (
                              <TooltipComponent
                                title="Block"
                                position="top"
                                id="block_cutomer"
                                divStyle={{
                                  verticalAlign: "unset",
                                  margin: "0",
                                }}
                                tooltipStyle={{
                                  minWidth: "65px",
                                  padding: "5px",
                                }}
                              >
                                <GButton
                                  variant="linkable"
                                  onClick={(e) => {
                                    setSelectedStatusType("Block");
                                    setSelectedHostId(c.id);
                                    setBlockDialogOpen(true);
                                  }}
                                >
                                  <Block
                                    fontSize="small"
                                    style={{ color: "#EBAE25" }}
                                  />
                                </GButton>
                              </TooltipComponent>
                            )}
                            {getUserType() == true && c.status === 1 && (
                              <TooltipComponent
                                title="Unblock"
                                position="top"
                                id="unblock_cutomer"
                                divStyle={{
                                  verticalAlign: "unset",
                                  margin: "0",
                                }}
                                tooltipStyle={{
                                  minWidth: "65px",
                                  padding: "5px",
                                }}
                              >
                                <GButton
                                  variant="linkable"
                                  onClick={(e) => {
                                    setSelectedStatusType("Unblock");
                                    setSelectedHostId(c.id);
                                    setBlockDialogOpen(true);
                                  }}
                                >
                                  <Unblock
                                    fontSize="small"
                                    style={{
                                      fill: "#bbb",
                                      height: "20px",
                                      width: "20px",
                                    }}
                                  />
                                </GButton>
                              </TooltipComponent>
                            )}
                            {getUserType() == true ||
                            isPermissionExist("host_update") ? (
                              <GButton
                                variant="linkable"
                                onClick={(e) => {
                                  setSelectedHostData(c);
                                  setChvisible(true);
                                }}
                              >
                                <Edit fontSize="small" />
                              </GButton>
                            ) : (
                              ""
                            )}
                            {getUserType() == true ||
                            isPermissionExist("host_destroy") ? (
                              <GButton
                                variant="linkable"
                                onClick={(e) => {
                                  setSelectedHostId(c.id);
                                  setDialogOpen(true);
                                }}
                              >
                                <Delete fontSize="small" />
                              </GButton>
                            ) : (
                              ""
                            )}
                          </TableRowData>
                        ) : (
                          ""
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Host"
          text="Are you sure you want to delete this host?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteHost();
          }}
        ></GConfirmDialog>
      )}

      {blockDialogOpen === true && (
        <GConfirmDialog
          open={blockDialogOpen}
          title={selectedStatusType + " Host"}
          text={
            "Are you sure you want to " + selectedStatusType + " this Host?"
          }
          handleClose={() => setBlockDialogOpen(false)}
          onConfirmClose={() => {
            setBlockDialogOpen(false);
            blockHost();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={chvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setChvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Host Form Modal"
      >
        <HostForm
          chvisible={chvisible}
          setChvisible={setChvisible}
          data={selectedHostData}
          onSubmit={getHosts}
          settings={settings}
        />
      </Modal>
    </div>
  );
}
