import React, { useState, useEffect, useRef } from "react";
import { ApiService } from "../services";
import styled from "styled-components";
import { Search, Close, Check } from "@material-ui/icons";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import useLocalStorage from "../useAsyncStorage";
import useDebounce from "../useDebounce";
import { ReactComponent as StoreIcon } from "../assets/images/sidebar/customers.svg";

const LI = styled.li`
  padding: 6px 8px;
  color: #333;
  font-size: 13.5px;
  cursor: pointer;
  display: flex;
  &:hover {
    background: #EBAE25;
    color: #fff;
  }
`;
const DIV = styled.div`
  background: transparent;
  border-radius: 4px;

  &:hover {
    background: #e6e7eb;
  }
  &.active {
    background: #f0d3c8;
    &:hover {
      background: #f0d3c8;
    }
  }
`;

const Input = styled.input`
  width: calc(100% - 45px);
  max-width: 450px;
  margin: 5px 0 0;
  height: 30px;
  background: transparent;
  border: 0px;
  outline: 0;
  box-shadow: none;
  color: #777;
  padding-left: 10px;
`;
export const HostFilter = ({
  value,
  handleHostChange,
  handleBlur,
  handleFocus,
  style,
}) => {
  const [initialLoading, setInitialloading] = useState(true);
  const [searchWord, setSearchWord] = useState("");
  const debouncedSearchTerm = useDebounce(searchWord, 500);
  const wrapperHostRef = useRef(null);
  const [showFilterList, setShowFilterList] = useDetectOutsideClick(
    wrapperHostRef,
    false
  );
  const [selectedHosts, setSelectedHosts] = useState([]);
  const [hostsFilterList, setHosts] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    console.log(value);
    if (initialLoading == true) {
      if (value.length == 0) {
        // setSelectedHosts([])
        setSelectedValues([]);
      }
      setInitialloading(false);
    }
  }, [value]);

  const getHosts = () => {
    ApiService({
      method: "GET",
      route: "hosts?per_page=50&search=" + searchWord,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setHosts(response.data.data.data);
          //console.log(selectedValues);
          let newArray = [];
          response.data.data.data.map((values) => {
            // console.log(value.length);
            // console.log(selectedValues.length);
            if (value.length == selectedValues.length) {
              if (selectedValues.length > 0) {
                handleHostChange(selectedValues);
                return;
              }
            } else {
              value.map((s) => {
                if (values.id == s) {
                  let findIndx = newArray.findIndex((n) => n.id == s);
                  if (findIndx == -1) {
                    newArray.push(values);
                  }
                }
              });
              value.map((s) => {
                let findInd = newArray.findIndex((n) => n.id != s);
                if (findInd == -1) {
                  let findIndxx = newArray.findIndex((n) => n.id == s);
                  if (findIndxx == -1) {
                    newArray.push({ id: s });
                  }
                }
              });
            }
          });
          setSelectedValues(newArray);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getHosts();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (initialLoading == false) {
      let selectedHostIds = [];
      console.log(selectedHosts);
      setSelectedValues([...selectedHosts]);
      if (selectedHosts.length > 0) {
        selectedHosts.map((s) => {
          selectedHostIds.push(s.id);
        });
      }
      handleHostChange(selectedHostIds);
    }
  }, [selectedHosts]);

  return (
    <div
      id="hostFilter"
      ref={wrapperHostRef}
      style={Object.assign(
        {},
        {
          width: "212px",
          display: "inline-block",
          position: "relative",
          maxWidth: "300px",
          // verticalAlign: "middle",
        },
        style
      )}
    >
      <DIV
        style={{
          // background: "#fff",
          // border: "1px solid rgb(226, 226, 226)",
          borderRadius: "4px",
          minHeight: "30px",
          fontSize: "14px",
          color: "#555",
          boxSizing: "border-box",
          padding: "1px 5px 1px 4px",
          fontWeight: "500",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          setShowFilterList(true);
          setSearchWord("");
        }}
        className={value.length > 0 ? "active" : ""}
      >
        {" "}
        <StoreIcon
          style={{
            fill: "#4D4D4D",
            margin: "0px 8px 0 2px",
            width: "14px",
            height: "14px",
          }}
        />
        {value.length > 0 ? (
          <>
            <span
              style={{
                color: "#555",
                padding: "1px 3px 2px 0",
                fontWeight: "550",
                userSelect: "none",
              }}
            >
              {value.length} {value.length > 1 ? "Hosts" : "Host"}
            </span>

            {/*  <span
              style={{
                // width: "calc(100% - 10px)",
                // display: "inline-block",
                display: "inline-flex",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                verticalAlign: "top",
                flexWrap: "wrap",
                padding: "2px 5px",
              }}
            >
             
            {selectedValues.map((s, i) => {
              return (
                <span
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    margin: "2px",
                    background: "#FCDBDB",
                    borderRadius: "20px",
                    padding: "2px 5px",
                  }}
                >
                  {s.title}
                  <Close
                    onClick={() => {
                      selectedValues.splice(i, 1);
                      setSelectedHosts([...selectedValues]);
                      setShowFilterList(false);
                    }}
                    style={{
                      fontSize: "14px",
                      marginLeft: "2px",
                      cursor: "pointer",
                    }}
                  />
                </span>
              );
            })} 
            </span>*/}
            <Close
              onClick={() => {
                setSelectedHosts([]);
                setShowFilterList(false);
              }}
              style={{
                fontSize: "14px",
                marginLeft: "2px",
                cursor: "pointer",
              }}
            />
          </>
        ) : (
          <span
            style={{
              color: "#555",
              padding: "1px 3px 2px 0",
              fontWeight: "550",
              userSelect: "none",
            }}
          >
            All Hosts
          </span>
        )}
        {/* <KeyboardArrowDown
          style={{
            marginLeft: "auto",
            fontSize: "19px",
            color: "#555",
          }}
        /> */}
      </DIV>
      {showFilterList === true ? (
        <div
          style={{
            background: "#fff",
            position: "absolute",
            zIndex: "9",
            boxSizing: "border-box",
            width: "100%",
            minWidth: "180px",
            padding: "0 2px 5px",
            border: "1px solid rgb(226, 226, 226)",
            marginTop: "5px",
          }}
        >
          <React.Fragment>
            <Input
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Search.."
            />
            <Search
              fontSize="small"
              style={{
                verticalAlign: "middle",
                fontSize: "22px",
                color: "#ccc",
              }}
            />
          </React.Fragment>
          <ul
            style={{
              listStyleType: "none",
              padding: "5px 0",
              margin: "0",
              borderTop: "1px solid #e2e2e2",
              height: "220px",
              overflow: "auto",
            }}
          >
            {hostsFilterList.map((c, i) => {
              return (
                <LI
                  key={i}
                  onClick={() => {
                    let findInd = selectedValues.findIndex((s) => s.id == c.id);
                    if (findInd == -1) {
                      selectedValues.push(c);
                      setSelectedHosts([...selectedValues]);
                    } else {
                      selectedValues.splice(findInd, 1);
                      setSelectedHosts([...selectedValues]);
                    }
                  }}
                >
                  {c.title}

                  {selectedValues
                    .filter((s) => s.id == c.id)
                    .map((c) => {
                      return (
                        <Check
                          style={{
                            float: "right",
                            marginLeft: "auto",
                            width: "18px",
                            height: "18px",
                          }}
                        />
                      );
                    })}
                </LI>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
