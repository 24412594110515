import React, { useState, useEffect } from "react";
import "../css/invoice.css";
import { ApiService, ImgUrl, addDefaultLogo,getProductTitle } from "../services";
import { useParams } from "react-router-dom";

import useSettings from "../useSettings";

function OrderInvoice(props) {
  const setting = useSettings();
  let { bookingId } = useParams();
  const [invoiceData, setInvoiceData] = useState({});
  const [linksData, setLinksData] = useState({
    invoice_logo: {},
    invoice_header_text: {},
    invoice_footer_text: {},
  });

  const getInvoiceSettings = () => {
    ApiService({ method: "GET", route: "setting/invoice" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setLinksData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getOrderDetail = () => {
    ApiService({ method: "GET", route: "orders/" + bookingId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setInvoiceData(response.data.data);
          getInvoiceSettings();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (
      localStorage.getItem("teamId") && localStorage.getItem("access_token")) {
      getOrderDetail();
    } else {
      window.location = "/";
    }
  }, []);

  return (
    <div className="invoiceDiv">
      <div
        className="flex"
        style={{
          width: "40%",
        }}
      >
        <img
          src={ImgUrl("logo") + "/" + linksData.invoice_logo.value}
          alt="logo"
          onError={addDefaultLogo}
        />

        {invoiceData.host?.id && (
          <>
           <label>Host</label>
            <p>{invoiceData.host?.title || ""}</p>
            <p>{invoiceData.host?.phone || ""}</p>
          </>
        )}
      </div>
      <div
        className="flex"
        style={{
          width: "60%",
        }}
      >
        <div>
          <p>{linksData.invoice_header_text.value}</p>
          <div
            className="flex"
            style={{
              width: "50%",
            }}
          >
            <label>Booking Date</label>
            <p>{invoiceData.created ? invoiceData.created_full : ""}</p>

            <br />
            <label>Invoice Number</label>
            <p>{"#" + bookingId}</p>
          </div>
          <div
            className="flex"
            style={{
              width: "50%",
            }}
          >
            <label>Invoice</label>
            <p>{invoiceData.customer ? invoiceData.customer.title : ""}</p>
            <p>{invoiceData.customer ? invoiceData.customer.phone : ""}</p>
            <p>
              {invoiceData.delivery
                ? invoiceData.delivery.delivery_address
                : ""}
            </p>

            <br />

            {invoiceData.product_id && (
              <>
                <label>{getProductTitle()} Info</label>
                <p>{invoiceData.product_title || ""}</p>
                <p>{invoiceData.product?.address || ""}</p>
              </>
            )}
          </div>
        </div>
      </div>

      <div>
        <br />
        <label>Payment Summary</label>
        <table>
          {invoiceData.payment_summary ? (
            <tbody>
              {invoiceData.payment_summary?.data.map((payment, ind) => {
                return (
                  <tr className={payment.title + " payment"}>
                    <td>{payment.title}</td>
                    <td>{payment.value}</td>
                  </tr>
                );
              })}

              <tr className="payment">
                <td>Total ({invoiceData.gateway_display})</td>
                <td>
                  {setting?.currency_symbol}
                  {invoiceData.payment_summary.total_display}
                </td>
              </tr>
            </tbody>
          ) : (
            ""
          )}
        </table>
      </div>
      <br />

      <label>
        Thank you for using the services of {setting?.project_name}.
      </label>
      <p>Powered by OnBooking</p>
      <br />
      <p>{linksData.invoice_footer_text.value}</p>
    </div>
  );
}

export default OrderInvoice;
