import React, { useState } from "react";
import { Switch, Route, useParams } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import NotificationsSetting from "./notificationSettings";
import GText from "../gComponents/gText";

export default function NotificationTypes() {
  let { type } = useParams();
  let { userType } = useParams();

  const notificationRoutes = [
    {
      title: "customer",
      link: "/settings/order-notifications/customer",
      state: "setting",
    },
    {
      title: "host",
      link: "/settings/order-notifications/host",
      state: "setting",
    },
    {
      title: "admin",
      link: "/settings/order-notifications/admin",
      state: "setting",
    },
  ];
  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={"Booking Notifications"}
        style={{ marginBottom: "15px", textTransform: "capitalize" }}
      />
      <GTabs routes={notificationRoutes} active={userType}></GTabs>
      <div className="listPaper">
        <div>
          <Switch>
            <Route path="/settings/:type-notifications/:userType">
              <NotificationsSetting type={type} userType={userType} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}
