import React, { useState } from "react";
import { ApiService } from "../services";
import GListCard from "../gComponents/gListCards";
import GButton from "../gComponents/gButton";
import { useSnackbar } from "react-simple-snackbar";
import CityPopup from "../forms/cityForm";
import GText from "../gComponents/gText";
import useDataFctory from "../useDataFactory";
import Modal from "react-modal";

export default function Cities() {
  const [selectedCity, setSelectedCity] = useState({});
  const [cityfvisible, setCityfvisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [openSnackbar] = useSnackbar();
  const {
    loading,
    data: citiesData,
    Placeholder,
    refreshData,
    CardShimmer,
  } = useDataFctory("cities", false);

  function deleteCity(selectedId) {
    ApiService({ method: "DELETE", route: "cities/" + selectedId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          refreshData();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GButton
        variant="condensed"
        children="Add"
        onClick={() => {
          setSelectedCity({});
          setIsEmpty(true);
          setCityfvisible(true);
        }}
        style={{
          float: "right",
          marginTop: "5px",
        }}
      />
      <GText g2 bold text={"Cities"} style={{ marginBottom: "15px" }} />

      <div className="listPaper">
        <div
          style={{
            maxHeight: "calc(100vh - 270px)",
            overflowY: "auto",
            padding: "5px",
          }}
        >
          {loading === true ? (
            <CardShimmer />
          ) : citiesData.length === 0 ? (
            <Placeholder />
          ) : (
            citiesData.map((c, pi) => {
              return (
                <GListCard
                  setting="city"
                  title={c.title}
                  listActions={true}
                  id={c.id}
                  key={c.id}
                  onEdit={() => {
                    setSelectedCity(c);
                    setIsEmpty(false);
                    setCityfvisible(true);
                  }}
                  onDelete={deleteCity}
                  data={c}
                ></GListCard>
              );
            })
          )}
        </div>
      </div>

      <Modal
        isOpen={cityfvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCityfvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="City Form Modal"
      >
        <CityPopup
          cityfvisible={cityfvisible}
          setCityfvisible={setCityfvisible}
          data={selectedCity}
          isEmpty={isEmpty}
          onSubmit={(response) => {
            openSnackbar(response.message);
            refreshData();
          }}
        />
      </Modal>
    </div>
  );
}

//export default Customers;
