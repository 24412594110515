import React from "react";
import styled from "styled-components";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import GButton from "../gComponents/gButton";

const Pagination = styled.div`
  text-align: right;
  ul {
    display: inline-flex;
    list-style-type: none;
    margin: 0 0 0;
    padding-left: 20px;

    li {
      padding: 5px 10px;
      font-size: 14px;

      button.pageButton {
        padding: 0px;
        color: #777;

        &:hover:enabled {
          color: #000;
        }
      }
      svg {
        font-size: 1rem;
      }
    }
  }
`;

export default function gPagination(props) {
  return (
    <Pagination style={props.style}>
      <ul style={props.listStyle}>
        <li>
          {props.from || 0} - {props.to || 0} of {props.count}
        </li>
        <li>
          <GButton
            variant="linkable pageButton"
            disabled={props.prevDisabled}
            onClick={props.onChangePrevPage}
          >
            <ArrowBackIos fontSize="small" />
          </GButton>
        </li>
        <li>
          <GButton
            variant="linkable pageButton"
            disabled={props.nextDisabled}
            onClick={props.onChangeNextPage}
          >
            <ArrowForwardIos fontSize="small" />
          </GButton>
        </li>
      </ul>
    </Pagination>
  );
}
