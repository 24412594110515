import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import useSettings from "../useSettings";
import GText from "../gComponents/gText";
import GInput from "../gComponents/gInput";
import GButton from "../gComponents/gButton";
import styled from "styled-components"

const ContentDiv = styled.div`
padding:12px 5px;
p{
  margin:0;letter-spacing:0.2px;
  b{
    font-weight:550
  }
}
ol{
  margin: 10px 0;
  padding-left: 30px;
  li{
    padding:3px 0 3px 5px;letter-spacing:0.2px;line-height:24px;
    b{
      font-weight:550
    }
  }
}
`;

export default function WebCustomDomain(props) {
  const settings = useSettings();
  const [openSnackbar] = useSnackbar();
  const [domain, setDomain] = useState(settings?.domain || "");
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setDomain(settings?.domain || "");
  }, [settings]);
 
  function updateDomain() {
    setIsDisabled(true);
    if (!domain) {
      openSnackbar("Please enter you domain");
      setIsDisabled(false);
      return;
    }
    ApiService({
      method: "POST",
      route: "web/domain",
      body: {
        domain: domain,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          settings.domain = domain;
          let newSettings = { ...settings };
          localStorage.setItem("settings", JSON.stringify(newSettings));
        }
        openSnackbar(response.data.message);
        setIsDisabled(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={"Link Custom Domain"}
        style={{ marginBottom: "15px" }}
      />

      <div className="listPaper">
        <ContentDiv>
        <p>Please follow the below steps for subdomain (Ex. <b>order.yourcompany.com</b>) -</p>
       <ol>
         <li>Please point your subdomain’s CNAME to <b>web.ordefy.com</b></li>
         <li>Enter your subdomain name below and Press Link Domain.</li>
         <li>If everything goes good, you will see your subdomain name in that field and you can access your ordering website via that subdomain link.</li>
         <li>If any error pops up, you can correct that or you can contact the chat support.</li>
       </ol>
       <br/>
       <p>Please follow the below steps for your main domain (Ex. <b>yourcompany.com</b>) -</p>
       <ol>
         <li>Point the <b><i>www.yourcompany.com</i></b> CNAME to <b>web.ordefy.com</b></li>
         <li>Forward your main domain to <b>www.yourcompany.com</b></li>
         <li>Enter your domain name with www. prefix below and Press Link Domain.</li>
         <li>If everything goes good, you will see your domain name in that field and you can access your ordering website via that domain link.</li>
         <li>If any error pops up, you can correct that or you can contact the chat support.</li>
       </ol>
       </ContentDiv>
        {/* <GText g4 text={"Domain Setting"} style={{ margin: "20px 0" }} /> */}


        <GText text={"Domain Name"} style={{ margin: "5px" }} />
        <div style={{ marginBottom: "20px", display: "flex" }}>
          <GInput
            type="text"
            placeholder=""
            name="domain"
            id="domain"
            value={domain}
            disabled={settings?.domain}
            display="block"
            onChange={(e) => {
              setDomain(e.target.value);
            }}
            divStyle={{ padding: "0 5px", minWidth: "300px" }}
          />
          {!settings?.domain ? (
            <GButton
              variant="condensed"
              children={"Link Domain"}
              onClick={() => updateDomain()}
              loading={isDisabled}
            />
          ) : (
            <a href={"https://" + settings?.domain} target={"blank"}>
              <GButton variant="condensed" children={"Go to Website"} />
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
