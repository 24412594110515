import React, { useState } from "react";
// import { Button} from '@material-ui/core';
import styled from "styled-components";
import GButton from "./gButton";
import GSwitch from "./gSwitch";
import { Edit, Delete, LockOpen } from "@material-ui/icons";
import GConfirmDialog from "./gConfirmDialog";
import GActions from "./gActionsStyle";

const ListCard = styled.div`
  padding: 0.8em;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 4px;
  background: #f4f5f8;
  width: 25%;
  min-width: 220px;
  display: inline-block;
  box-sizing: border-box;
  margin: 10px;
  vertical-align: top;
  /*  
  &:hover{
    color:rgba(0, 0, 0, 0.26);
  }  */

  h4 {
    margin: 0px;
    font-weight: 400;
  }
`;

export default function GListCard(props) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDelete = (e) => {
    props.onDelete(props.id);
  };
  const handleEdit = () => {
    props.onEdit(props.data);
  };
  const toggleValue = (nVal, sId) => {
    props.onSwitchToggle(nVal, sId);
  };

  return (
    <ListCard style={props.style}>
      {props.switch === true && (
        <GSwitch
          id={props.id}
          float="right"
          uId={props.id}
          currentValue={
            props.switchValue === "true" ||
            props.switchValue === "1" ||
            props.switchValue === 1
              ? 1
              : 0
          }
          onSwitchChange={toggleValue}
        />
      )}

      <h4
        className={props.switch === true ? "switchClassTitle" : ""}
        style={Object.assign(
          {},
          {
            height: props.editAction === true ? "50px" : "auto",
            textOverflow: "ellipsis",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            display: "-webkit-box",
            WebkitLineClamp: props.editAction === true ? "2" : "auto",
          },
          props.titleStyle
        )}
      >
        {props.title}
      </h4>

      <div style={{ paddingTop: "10px", color: "#777", fontWeight: "400" }}>
        {props.subtitle}
        {props.dropdownActions === true && (
          <GActions
            edit={props.edit}
            delete={props.delete}
            onEdit={() => props.onEdit(props.data)}
            onDelete={() => setDialogOpen(true)}
            style={{ float: "right" }}
          />
        )}
      </div>
      {props.listActions === true ? (
        <div style={{ float: "right" }}>
          {props.onEditPermissions && (
            <GButton variant="linkable">
              <LockOpen
                fontSize="small"
                onClick={() => props.onEditPermissions(props.data)}
              />
            </GButton>
          )}
          {props.onEdit && (
            <GButton variant="linkable">
              <Edit fontSize="small" onClick={() => handleEdit()} />
            </GButton>
          )}
          <GButton variant="linkable">
            <Delete fontSize="small" onClick={() => setDialogOpen(true)} />
          </GButton>
        </div>
      ) : (
        ""
      )}

      {props.editAction === true ? (
        <div>
          {/* <br /> */}
          <GButton
            variant="linkable"
            style={{
              fontSize: "0.9rem",
              textDecoration: "underline",
              paddingLeft: "0px",
              verticalAlign: "middle",
            }}
            onClick={() => handleEdit()}
          >
            Edit
          </GButton>
          <GButton
            variant="linkable"
            style={{
              fontSize: "0.9rem",
              padding: "0 0 0 5px",
              verticalAlign: "middle",
              // color: "#e7222d",
              textDecoration: "underline",
            }}
            onClick={() => setDialogOpen(true)}
          >
            Delete
          </GButton>
        </div>
      ) : (
        ""
      )}

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={props.setting ? "Delete " + props.setting : "Delete"}
          text={
            props.setting
              ? "Are you sure you want to delete " + props.setting + "?"
              : "Are you sure you want to delete ? "
          }
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            handleDelete();
          }}
        ></GConfirmDialog>
      )}
    </ListCard>
  );
}
