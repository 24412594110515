import React, { useState, useEffect } from "react";
import { ApiService, getUserType, isPermissionExist,getProductTitle } from "../services";
import GTabs from "../gComponents/gTabs";
import Products from "./items";
import styled from "styled-components";

const Div = styled.div`
  margin-right: 0;
  vertical-align: top;
  width: 420px;
  background: #000;
  display: inline-block;
  transition: margin-right 0.5s;
  height: calc(100vh - 65px);
  &.show {
    display: inline-block;
    margin-right: -420px;
  }
`;

const Listview = (props) => {
  const tabRoutes = [getProductTitle()] || [];
  const [typeValue, setTypeValue] = useState(tabRoutes[0]);

  return (
    <Div className={props.viewTaskList === true ? "show" : ""}>
      <GTabs
        routes={tabRoutes}
        active={typeValue}
        type="single"
        onClick={(newVal) => setTypeValue(newVal)}
        style={{
          background: "#222326",
          border: "1px solid #101010",
          padding: "0 10px",
        }}
        ulStyle={{ height: "39px" }}
        spanStyle={{ padding: "9px 20px", lineHeight: "40px", color: "#ccc" }}
        spanActiveStyle={{ color: "#bbb", borderBottom: "2px solid #999" }}
      ></GTabs>

      <div
        style={{
          background: "#151515",
          minHeight: "calc(100vh - 100px)",
          borderLeft: "1px solid #101010",
        }}
      >
        {typeValue == getProductTitle() ? (
          <Products
            onClick={(d) => props.onLocateDriver(d)}
            driversData={props.driversData}
            updatedDriver={props.updatedDriver}
          />
        ) : (
          ""
        )}
      </div>
    </Div>
  );
};

export default Listview;
