import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import GButton from "../gComponents/gButton";
import "../styledComponents/htmlEditorStyles.css";
import { useSnackbar } from "react-simple-snackbar";

export default function PrivacyPolicy() {
  const [disclaimerData, setDisclaimerData] = useState({});
  const [openSnackbar] = useSnackbar();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const getHtml = (editorState) => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  //convertFromRaw(editorState)

  const onEditorStateChange = (editorState) => {
    console.log(editorState);
    return setEditorState(editorState);
  };

  const onContentStateChange = (contentState) => {
    console.log("as HTML:", contentState);
    //this.setState({ contentState });
  };
  const getPrivacyPolicy = () => {
    ApiService({ method: "GET", route: "setting/identifier/disclaimer" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setDisclaimerData(response.data.data);
          setEditorState(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                htmlToDraft(response.data.data.value).contentBlocks
              )
            )
          );
          console.log(editorState);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  const updatePolicy = () => {
    var text = getHtml(editorState);
    var policyValue = {
      value: text,
    };
    ApiService({
      method: "PUT",
      route: "setting/" + disclaimerData.id,
      body: policyValue,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setEditorState(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                htmlToDraft(response.data.data.value).contentBlocks
              )
            )
          );
          console.log(editorState);
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="rightInnerView">
      <h2>Disclaimer</h2>
      <div className="listPaper">
        <Editor
          editorState={editorState}
          initialContentState={editorState}
          editorContent={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onContentStateChange={onContentStateChange}
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "list",
              "textAlign",
              "colorPicker",
              "link",
              "emoji",
              "image",
            ],
            inline: { options: ["bold", "italic", "underline"] },
            list: { options: ["unordered", "ordered"] },
          }}
        />
        {/* {getHtml(editorState)} */}
        <br />
        <GButton
          variant="condensed"
          children="Update"
          onClick={updatePolicy}
        ></GButton>
      </div>
    </div>
  );
}
