import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useSnackbar } from "react-simple-snackbar";
import GButton from "../gComponents/gButton";

export default function NotificationsPUSHForm(props) {
  console.log(props);
  const [openSnackbar] = useSnackbar();
  const [selectedNofifyType, setSelectedNotifyType] = useState(props.data);
  const [selectionStart, setSelectionStart] = useState(0);
  const initialValues = {
    medium: props.data ? props.data.medium : "push",
    user_type: props.userType,
    event_identifier: props.eventIdentifier,
    status: props.data ? props.data.status : "1",
    title: props.data ? selectedNofifyType.title : "",
    sub_title: props.data ? selectedNofifyType.sub_title : "",
  };

  useEffect(() => {
    setSelectedNotifyType(props.data);
  }, [props]);

  const onSubmit = (values) => {
    console.log(values);
    if (props.data) {
      values.id = props.data.id;
    }

    if (props.module === "task") {
      values.notification_type = props.notificationType
        ? props.notificationType
        : "delivery";
    }
    // return;
    var hitMethod = props.data ? "PUT" : "POST";
    var hitUrl = props.data ? "notification-settings" : "notification-settings";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log("r" + response.data.status_code);
        if (response.data.status_code === 1) {
          props.onSubmit();
        }
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    sub_title: Yup.string().required("Required"),
  });

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => {
          const handleSelectChange = (newVal, fieldName) => {
            if (!newVal) {
              return;
            }
            let cursorPos2 = selectionStart;
            var v2 = values[fieldName];
            var var2Val = "**" + newVal + "**";
            var textBefore2 = v2.substring(0, cursorPos2);
            var textAfter2 = v2.substring(cursorPos2, v2.length);
            setFieldValue(fieldName, textBefore2 + var2Val + textAfter2);
          };
          return (
            <Form>
              <div>
                <FormikControl
                  control="select"
                  as="select"
                  name="variable"
                  options={props.variables}
                  key_title="title"
                  key_value="identifier"
                  onChange={(e) => handleSelectChange(e.target.value, "title")}
                  style={{ width: "170px", float: "right", border: "0px" }}
                />

                <FormikControl
                  control="input"
                  type="text"
                  label="Title"
                  name="title"
                  onBlur={(e) => setSelectionStart(e.target.selectionStart)}
                />
              </div>
              <div>
                <FormikControl
                  control="select"
                  as="select"
                  name="variable1"
                  options={props.variables}
                  key_title="title"
                  key_value="identifier"
                  onChange={(e) =>
                    handleSelectChange(e.target.value, "sub_title")
                  }
                  style={{ width: "170px", float: "right", border: "0px" }}
                />

                <FormikControl
                  control="input"
                  as="textarea"
                  type="text"
                  label="Subtitle"
                  name="sub_title"
                  onBlur={(e) => setSelectionStart(e.target.selectionStart)}
                  rows={4}
                />
              </div>

              <GButton
                type="submit"
                variant="condensed"
                children={props.data ? "Update" : "Add"}
                style={{ float: "right", margin: "15px" }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
