import React, { useState, useEffect } from "react";
import { TableContainer } from "@material-ui/core";
import { ApiService } from "../services";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import { CheckCircleOutline, AccountBalanceWallet } from "@material-ui/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import GEmpty from "../gComponents/gEmpty";
import GButton from "../gComponents/gButton";
import GTableShimmer from "../gComponents/gTableShimmer";
import { useSnackbar } from "react-simple-snackbar";
import GPagination from "../gComponents/gPagination";
import useSettings from "../useSettings";
import QueryWith from "../Query";
import GSelectbox from "../gComponents/gSelectbox";
import { HostFilter } from "../components/hostFilterComponent";

const columns = [
  { id: "host_name", label: "Host Name", minWidth: 120 },
  { id: "amount", label: "Amount", minWidth: 80 },
  { id: "status", label: "Status", minWidth: 50 },
  { id: "created", label: "Created", minWidth: 60, align: "center" },
  { id: "approved", label: "Approved", minWidth: 60, align: "center" },
];

const filterStatus = [
  {
    title: "Pending",
    value: "pending",
  },
  {
    title: "Accepted",
    value: "accepted",
  },
];

export default function Payouts() {
  const settings = useSettings(true);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [openSnackbar] = useSnackbar();
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [withdrawalPaginationData, setWithdrawalPaginationData] = useState("");
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    status: params.get("status") ? params.get("status") : "pending",
    host_id:
      params.get("host_id")
        ? params.get("host_id")
        : "",
  });

  function getWithdrawals() {
    var params = QueryWith(history.location, filters);
    if (params.length > 0) {
      history.push({
        pathname: "/payouts",
        state: "payout",
        search: params[0],
      });
    }
    ApiService({
      method: "GET",
      route: "host/withdrawal",
      body: filters,
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          setWithdrawalData(response.data.data.data);
          setWithdrawalPaginationData(response.data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    setLoading(true);
    getWithdrawals();
  }, [filters]);

  useEffect(() => {
    setFilters({ page: 1, status: "pending", host_id: "" });
  }, [history]);

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  const approveWithdraw = (c) => {
    ApiService({
      method: "PUT",
      route: "host/withdrawal/accept/" + c.id,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        getWithdrawals();
      }
      openSnackbar(response.data.message);
      return;
    });
  };

  return (
    <div className="mainInnerView">
      <h1>
        <AccountBalanceWallet /> Payouts
      </h1>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          marginBottom: "15px",
          gap: "10px",
        }}
      >
        <HostFilter
          value={filters.host_id}
          handleHostChange={(value) => {
            setFilters({ ...filters, host_id: value, page: 1 });
          }}
        />

        <GSelectbox
          defaultvalue={filters.status}
          showSelectOption={false}
          data={filterStatus}
          id="status_filter"
          option_key="title"
          option_value="value"
          onChange={(newVal) => {
            setFilters({ ...filters, status: newVal, page: 1 });
          }}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            minWidth: "150px",
          }}
        />

        {withdrawalData.length > 0 && (
          <GPagination
            style={{ marginLeft: "auto" }}
            count={withdrawalPaginationData.total}
            from={withdrawalPaginationData.from || 0}
            to={withdrawalPaginationData.to || 0}
            onChangeNextPage={() =>
              handleChangePage(withdrawalPaginationData.next_page_url)
            }
            onChangePrevPage={() =>
              handleChangePage(withdrawalPaginationData.prev_page_url)
            }
            rowsPerPage={withdrawalPaginationData.per_page}
            prevDisabled={withdrawalPaginationData.prev_page_url ? false : true}
            nextDisabled={withdrawalPaginationData.next_page_url ? false : true}
          ></GPagination>
        )}
      </div>

      <div className="listPaper">
        {loading === true ? (
          <GTableShimmer />
        ) : (
          <TableContainer>
            {withdrawalData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns
                      .map((c) => {
                        return (
                          <TableHeader
                            key={c.id}
                            style={{
                              minWidth: c.minWidth,
                              textAlign: c.align,
                            }}
                          >
                            {c.label}
                          </TableHeader>
                        );
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {withdrawalData.map((c) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData>
                          <Link
                            to={{
                              pathname: "/host/" + c.host?.id + "/tasks",
                              state: "host",
                            }}
                          >
                            {c.host?.title}
                          </Link>
                        </TableRowData>
                        <TableRowData>
                          {settings.currency_symbol}
                          {c.amount}
                        </TableRowData>
                        <TableRowData style={{ textTransform: "uppercase" }}>
                          {c.status}
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {c.created}
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {filters.status === "pending" ? (
                            <GButton
                              variant="linkable"
                              onClick={(e) => {
                                approveWithdraw(c);
                              }}
                            >
                              <CheckCircleOutline fontSize="small" />
                            </GButton>
                          ) : (
                            <span>{c.updated}</span>
                          )}
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>
    </div>
  );
}
