import React, { useState, useEffect } from "react";
import { TableContainer } from "@material-ui/core";
import { ApiService } from "../services";
import { Payment } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GPagination from "../gComponents/gPagination";
import useSettings from "../useSettings";
import { CustomerFilter } from "../components/customerFilterComponent";
import GSelectbox from "../gComponents/gSelectbox";
import QueryWith from "../Query";

const columns = [
  { id: "booking_id", label: "Booking Id", minWidth: 80 },
  { id: "method", label: "Method", minWidth: 100, align: "" },
  // { id: "trans_id", label: "Trans Id", minWidth: 100 },
  { id: "customer_id", label: "Customer", minWidth: 120 },
  { id: "amount", label: "Amount", minWidth: 80 },
  { id: "status", label: "Status", minWidth: 50, align: "center" },
  { id: "created", label: "Created", minWidth: 80, align: "center" },
];

const filterTransStatus = [
  // {
  //   title: "Pending",
  //   value: "pending",
  // },
  {
    title: "Paid",
    value: "paid",
  },
];

export default function Transactions() {
  const [transactionData, setTransactionData] = useState([]);
  const [transactionPaginationData, setTransactionPaginationData] =
    useState("");
  const settings = useSettings(true);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    status: params.get("status") ? params.get("status") : "",
    customer_id: params.get("customer_id") ? params.get("customer_id") : "",
  });

  useEffect(() => {
    function getTransactions() {
      var params = QueryWith(history.location, filters);
      if (params.length > 0) {
        history.push({
          pathname: "/transactions",
          state: "transaction",
          search: params[0],
        });
      }

      ApiService({ method: "GET", route: "order/transactions", body: filters })
        .then((response) => {
          console.log(JSON.stringify(response));
          if (response.data.status_code === 1) {
            setTransactionData(response.data.data.data);
            setTransactionPaginationData(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getTransactions();
  }, [history, filters]);

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  return (
    <div className="mainInnerView">
      <h1>
        <Payment /> Transactions
      </h1>

      <div>
        <CustomerFilter
          value={filters.customer_id}
          style={{
            marginRight: "10px",
            marginBottom: "10px",
          }}
          handleCustomerChange={(value) => {
            setFilters({ ...filters, customer_id: value || "", page: "1" });
          }}
        />

        <GSelectbox
          placeholder="Select Status"
          defaultvalue={filters.status}
          data={filterTransStatus}
          id="status_filter"
          option_key="title"
          option_value="value"
          onChange={(newVal) => {
            setFilters({ ...filters, status: newVal, page: "1" });
          }}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            marginRight: "10px",
            minWidth: "150px",
            marginBottom: "10px",
          }}
        />
        <br />
      </div>

      {transactionPaginationData ? (
        <GPagination
          count={transactionPaginationData.total}
          from={transactionPaginationData.from}
          to={transactionPaginationData.to}
          onChangeNextPage={() =>
            handleChangePage(transactionPaginationData.next_page_url)
          }
          onChangePrevPage={() =>
            handleChangePage(transactionPaginationData.prev_page_url)
          }
          rowsPerPage={transactionPaginationData.per_page}
          prevDisabled={transactionPaginationData.prev_page_url ? false : true}
          nextDisabled={transactionPaginationData.next_page_url ? false : true}
        ></GPagination>
      ) : (
        ""
      )}

      <div className="listPaper">
        {!transactionPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer>
            {transactionData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactionData.map((c) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData>
                          <Link
                            to={{
                              pathname: "/booking/" + c.order_id,
                              state: "booking",
                            }}
                          >
                            {" "}
                            {c.order_id ? "#" + c.order_id : "-"}
                          </Link>
                        </TableRowData>
                        <TableRowData
                          style={{
                            textTransform: "uppercase",
                            // textAlign: "center",
                          }}
                        >
                          {c.image ? (
                            <img
                              src={c.image}
                              alt=""
                              style={{
                                verticalAlign: "middle",
                                display: "inline-block",
                                width: "40px",

                                padding: "0 7px 0 0",
                              }}
                            />
                          ) : (
                            ""
                          )}
                          {c.gateway || "-"}
                        </TableRowData>
                        {/* <TableRowData>
                            {c.transaction_id ? c.transaction_id : "-"}
                          </TableRowData> */}

                        <TableRowData>
                          <Link
                            to={{
                              pathname:
                                "/customer/" + c.customer.id + "/bookings",
                              state: "customer",
                            }}
                          >
                            {c?.customer.title}
                          </Link>
                        </TableRowData>
                        <TableRowData>
                          {settings.currency_symbol}
                          {c.amount}{" "}
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          <span
                            style={{
                              textTransform: "uppercase",
                              background: "#" + c.background,
                              color: "#" + c.color,

                              padding: "4px 12px",
                            }}
                          >
                            {c?.status_label}
                          </span>
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {c.created}
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>
    </div>
  );
}
