import React, { useState, useEffect } from "react";
import { ImgUrl, ApiService } from "../services";
import GEditable from "../gComponents/gEditable";
import GImageEditor from "../gComponents/gImageEditor";
import { useSnackbar } from "react-simple-snackbar";
import GText from "../gComponents/gText";

export default function InvoiceSettings(props) {
  const [openSnackbar] = useSnackbar();
  const [invoiceData, setInvoiceData] = useState({
    invoice_logo: {},
    invoice_header_text: {},
    invoice_footer_text: {},
  });

  useEffect(() => {
    getInvoiceSettings();
  }, []);

  const getInvoiceSettings = () => {
    ApiService({ method: "GET", route: "setting/invoice" })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          setInvoiceData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function updateField(newValue, selectedFieldId, field) {
    console.log(newValue + selectedFieldId);
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        value: newValue,
      },
    })
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.data.status_code === 1) {
          invoiceData[field] = response.data.data;
        } 
        openSnackbar(response.data.message);
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={"Invoice Settings"}
        style={{ marginBottom: "20px" }}
      />
      <div className="listPaper">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            boxSizing: "border-box",
            padding: "0 10px",
          }}
        >
          {invoiceData.invoice_logo && (
            <div style={{ margin: "0 30px 10px 0" }}>
              <label style={{ display: "block", margin: "10px 0" }}>
                Invoice Logo
              </label>
              <div
                style={{
                  background: "#f4f5f9",
                  padding: "10px",
                  display: "inline-block",
                }}
              >
                <GImageEditor
                  image={
                    ImgUrl("logo") + "/" + invoiceData.invoice_logo.value
                  }
                  onSubmit={(newValue, selectedFieldId) =>
                    updateField(newValue, selectedFieldId, "invoice_logo")
                  }
                  id={invoiceData.invoice_logo.id}
                  type="logo"
                  isPreviewImg={true}
                />
              </div>
            </div>
          )}

          <div>
            <GEditable
              key={invoiceData.invoice_header_text.id}
              type="text"
              title={true}
              editable={false}
              defaultValue={invoiceData.invoice_header_text.value}
              className={props.display}
              placeholder={invoiceData.invoice_header_text.title}
              id={invoiceData.invoice_header_text.id}
              onSubmit={(newValue, selectedFieldId) =>
                updateField(newValue, selectedFieldId, "invoice_header_text")
              }
              style={{ margin: "10px 0 20px" }}
              required={false}
            />

            <GEditable
              key={invoiceData.invoice_footer_text.id}
              type="text"
              title={true}
              editable={false}
              defaultValue={invoiceData.invoice_footer_text.value}
              className={props.display}
              placeholder={invoiceData.invoice_footer_text.title}
              id={invoiceData.invoice_footer_text.id}
              onSubmit={(newValue, selectedFieldId) =>
                updateField(newValue, selectedFieldId, "invoice_footer_text")
              }
              style={{ margin: "10px 0 20px" }}
              required={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
