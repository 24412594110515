import React, { useEffect } from "react";
import styled from "styled-components";
import GButton from "../gComponents/gButton";
import { Done, Clear } from "@material-ui/icons";
import { useSnackbar } from "react-simple-snackbar";

const TextField = styled.input`
  padding: 0.5em;
  font-size: 1rem;
  font-weight: 450;
  border-radius: 0px;
  width: calc(100% - 80px);
  box-shadow: none;
  outline: 0;
  font-family: unset;
  border: 0px;
  border-bottom: 1px solid #e2e2e2;
  color: #555;
  font-size: 0.95rem;

  &.block {
    display: block;
  }

  &.inline {
    display: inline-block;
  }
`;

const TextArea = styled.textarea`
  padding: 0.5em;
  font-size: 1rem;
  font-weight: 450;
  border-radius: 0px;
  width: calc(100% - 80px);
  box-shadow: none;
  outline: 0;
  font-family: unset;
  border: 0px;
  border-bottom: 1px solid #e2e2e2;
  color: #555;
  font-size: 0.95rem;

  &.block {
    display: block;
  }

  &.inline {
    display: inline-block;
  }
`;

const Title = styled.label`
  font-size: 0.95rem;
  font-weight: 450;
  display: inline-block;
  margin-bottom: 8px;
`;

const valueStyle = {
  margin: "5px 0",
  color: "#777",
  cursor: "pointer",
  padding: "0.5rem",
  borderBottom: "1px solid #e2e2e2",
  fontSize: "15.5px",
  fontWeight: "450",
  // width: "fit-content",
  minWidth: "325px",
};

const lableValueStyle = {
  margin: "2px 0",
  color: "#555",
  fontSize: "0.95rem",
  cursor: "pointer",
  background: "#f4f5f9",
  padding: "0.5rem",
  // width: "fit-content",
  minWidth: "300px",
  fontWeight: "450",
};

export default function GEditable(props) {
  const [openSnackbar] = useSnackbar();
  const [setEditable, setEditableOpen] = React.useState(false);
  const [editableId, setEditableId] = React.useState(props.id);
  const [selectedValue, setSelectedValue] = React.useState(
    props.defaultValue ? props.defaultValue : ""
  );

  useEffect(() => {
    setSelectedValue(props.defaultValue || "");
  }, [props]);

  const onSubmitHandler = (e) => {
    if (props.required === true) {
      if (!selectedValue) {
        openSnackbar(
          props.title === true
            ? props.placeholder + " is required."
            : "Value is required"
        );
        return;
      }
    }

    if (props.type === "email") {
      const re = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      var valid = re.test(selectedValue);
      console.log(valid);
      if (valid === false) {
        openSnackbar("Enter valid Email id");
        return;
      }
    }

    if (props.maxVal && props.maxVal < selectedValue) {
      openSnackbar(props.title + " must not be greater than " + props.maxVal);
      return;
    }

    setEditableOpen(false);
    props.onSubmit(selectedValue, editableId);
  };
  const onChangedHandler = (e) => {
    // if (!e.target.value) {
    //   return;
    // }
    setSelectedValue(e.target.value);
  };

  const onCancelHandler = (val) => {
    setSelectedValue(val);
    setEditableOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <div style={props.style}>
      {/* <div style={props.style} > 
          <div>{props.placeholder}</div>
          <p onClick={(e) => { setEditableOpen(true) }}>{selectedValue}</p>
      </div> */}

      {props.title === true && <Title>{props.placeholder}</Title>}
      {setEditable === true ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          {props.type === "textarea" ? (
            <TextArea
              type={props.type}
              defaultValue={selectedValue}
              className={props.display}
              placeholder={props.placeholder}
              id={editableId}
              onChange={onChangedHandler}
            ></TextArea>
          ) : props.type === "number" ? (
            <TextField
              type="text"
              pattern="[0-9]*"
              maxLength={props.maxLength || 7}
              value={selectedValue}
              className={props.display}
              placeholder={props.placeholder}
              id={editableId}
              onChange={(event) => {
                const re = /^[0-9.+\b]+$/;
                if (event.target.value && !re.test(event.target.value)) {
                  return;
                } else {
                  onChangedHandler(event);
                }
              }}
            ></TextField>
          ) : props.type === "alpha" ? (
            <TextField
              type="text"
              value={selectedValue}
              className={props.display}
              placeholder={props.placeholder}
              id={editableId}
              onChange={(event) => {
                const re = /^[a-zA-Z\b]+$/;
                if (event.target.value && !re.test(event.target.value)) {
                  return;
                } else {
                  onChangedHandler(event);
                }
              }}
            ></TextField>
          ) : (
            // ) : props.type === "email" ? (
            //   <TextField
            //     type="text"
            //     value={selectedValue}
            //     className={props.display}
            //     placeholder={props.placeholder}
            //     id={editableId}
            //     onChange={(event) => {
            //       const re = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2, 4}\b$/;
            //       if (event.target.value && !re.test(event.target.value)) {
            //         return;
            //       } else {
            //         onChangedHandler(event);
            //       }
            //     }}
            //   ></TextField>
            <TextField
              type={props.type}
              defaultValue={selectedValue}
              className={props.display}
              placeholder={props.placeholder}
              id={editableId}
              onChange={onChangedHandler}
            ></TextField>
          )}
          <div style={{ display: "inline-flex", verticalAlign: "bottom" }}>
            <GButton
              variant="linkable"
              onClick={onSubmitHandler}
              style={{ height: "35px" }}
            >
              <Done fontSize="small" />
            </GButton>
            <GButton
              variant="linkable"
              style={{ height: "35px" }}
              onClick={(e) => {
                onCancelHandler(props.defaultValue);
              }}
            >
              <Clear fontSize="small" />
            </GButton>
          </div>
        </div>
      ) : (
        <div>
          <p
            style={Object.assign(
              {},
              props.title === true ? lableValueStyle : valueStyle,
              props.inputStyle
            )}
            onClick={(e) => {
              setEditableOpen(true);
              setEditableId(props.id);
            }}
          >
            {selectedValue ? selectedValue : "-"}
          </p>
        </div>
      )}
    </div>
  );
}
